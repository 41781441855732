<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">Logout</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Logout",
  created() {
    console.log("Time to logout")
    this.$store.dispatch("auth/logout");
    this.$router.push("login")
  },
};
</script>
