<template>
  <LightboxContentLayout :ariaDescription="ariaDescription" :captionText="data.caption"
    :accessibilityTextFlag="accessibilityTextFlag">

    <div class="card">

      <div :style="{ textAlign: 'center' }">
        <div>If a tab hasn't opened click on the following</div>
        <div>
          <a v-if="data.thumbnail !== null" :href="urlPath" target="_blank" class="image-link">
            <v-img :src="data.thumbnail.file" class="link-thumbnail" />
            <span class="text-truncate centered-text" :style="{ maxWidth: '300px', padding: '10px' }">
              {{ urlPath }}
            </span>
          </a>
          <v-btn v-else class="mt-4" :href="urlPath" target="_blank" :style="{ maxWidth: '300px' }">
            <span class="text-truncate" :style="{ maxWidth: '300px', padding: '10px'}"> {{ urlPath }}</span>
          </v-btn>
        </div>
      </div>

    </div>
  </LightboxContentLayout>

</template>
<script>
import LightboxContentLayout from './LightboxContentLayout.vue';

export default {
  name: "InternalLinkCard",
  data: () => ({
    urlPath,
    fullLink,
  }),
  props: ["data", "ariaDescription", "accessibilityTextFlag"],
  components: {
    LightboxContentLayout
  },
  computed: {
    urlPath() {
      const location = new URL(window.location.href)
      const url = new URL(this.data.page.meta.html_url)
      return location.origin + url.pathname
    },

  }
}
</script>
<style scoped>
.card {
  height: 100%;
  width: 100%;
  background-color: rgb(32, 32, 32);
  border-radius: 5px;
  color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex: 1;
}

.card {
  height: 100%;
  width: 100%;
  background-color: rgb(32, 32, 32);
  border-radius: 5px;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link-thumbnail {
  margin-top: 10px;
  width: 300px;
  border-radius: 5px;
  opacity: 0.65;
}

.link-thumbnail:hover {
  opacity: 0.45;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: whitesmoke;
  position: relative;
}
</style>
