<template>

  <div class="wrapper">
    <div v-if="hasAccessibleText && accessibilityMode && accessibilityTextFlag"
      class="header text-center white--text pa-2" :id="ariaDescription" aria-hidden="false">{{
          accessibilityText
      }}
    </div>

    <div class="main" :style="isVimeoVideo">
      <slot></slot>
    </div>
    <div v-if="hasCaption" class="footer text-center pa-2 white--text">{{ captionText }}</div>

  </div>
</template>

<script>
export default {
  name: "LightboxContentLayout",

  data: () => ({
    dialog: true,
    isMobile: false
  }),
  props: ["data", "vimeoVideo","C", "accessibilityTextFlag", "accessibilityText", "ariaDescription", "captionText"],
  mounted() {
  },
  methods: {
  },
  computed: {
    hasCaption() {
      if (this.captionText !== undefined && this.captionText !== "") return true;
      return false;
    },
    hasAccessibleText() {
      if (this.accessibilityText !== undefined && this.accessibilityText !== "") return true;
      return false;
    },
    isVimeoVideo(){
      if(this.vimeoVideo == true){
        return 'overflow: hidden;'
      }else return ''
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
  },
};
</script>


<style scoped>
.header {
  max-height: 20%;
  overflow-y: auto;
  /* background-color: green; */
  word-wrap: break-word;
  writing-mode: horizontal-tb;
}

.main {
  /* flex: 2; */
  /* flex-grow: 2; */
  /* flex-shrink: 1; */
  flex-basis: 90%;
  overflow-y: auto;
  /* background-color:rgba(0,0,0,0.25); */  
}

.footer {
  max-height: 20%;
  overflow-y: auto;
  /* background-color: brown; */
  word-wrap: break-word;
  writing-mode: horizontal-tb;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 90vh
}

</style>