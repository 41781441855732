<template>
  <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}">
    <v-card :style="{ maxHeight: '100%', minHeight: '50%', maxWidth: '1600px', overflow: 'clip', overflowY: 'auto', }">
        <v-row  no-gutters>
          <v-col :style="{ padding: 0, display: 'flex', justifyContent: 'center', }"  cols="12" sm="12" md="6" lg="6" xl="6">
              <img 
              :style="{ objectFit: 'cover', overflow: 'hidden', height: '100%', position: 'sticky', top: 0, }"
              :src="data.image.file" 
              :alt="data.image.alternative_text" 
              :aria-label="data.image.accessibility_text" />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6"  :style="{ padding: '20px' }">
            <v-card-title>{{ data.title }}</v-card-title>
            <v-card-text>
              <ParagraphBlock :html="data.description"></ParagraphBlock>
            </v-card-text>
          </v-col>
        </v-row>
    </v-card>
  </div>


</template>
<script>

import ParagraphBlock from '../blocks/paragraphBlock.vue';
export default {
  props: ["data"],
components: {
  ParagraphBlock
}
}
</script>
<style>
  
</style>