<template>
  <div ref="divWidth">
    <v-container fluid>
      <v-row dense>
        <v-col
          @keydown.enter.exact.prevent="openGallery(i, card)"
          v-for="(card, i) in data.gallery_items"
          :key="card.id"
          :cols="colsBreakpoint"
          class="pa-6 d-flex"
        >
          <CardGalleryBlockCard
            :data="card"
            :cardHeight="minCardHeight"
            @keydown.enter.exact.prevent="testFunc()"
            @click="openGallery(i, card)"
          />
          <!-- <CardGalleryBlockCard
            v-else-if="data.type == 'external_link' && data.type == 'internal_page_link' "
            :data="card"
            @keydown.enter.exact.prevent="testFunc()"
            @click="openGallery(i, card)"
          /> -->
        </v-col>
      </v-row>
    </v-container>
    <div class="child-lightbox-container" v-if="showGallery">
      <v-scale-transition origin="center center">
        <LightboxGallery
          :content="contentData"
          :startIndex="startIndex"
          @closed="closeGallery()"
          :showLightbox="showGallery"
        />
      </v-scale-transition>
    </div>
  </div>
</template>

<script>
import CardGalleryBlockCard from "./cardGalleryBlockCard";
// import axios from "axios"

export default {
  name: "CardGalleryBlock",
  props: ["data", "srcWidth"],
  data: () => ({
    showGallery: false,
    startIndex: 0,
    srcSize: 0,
  }),
  mounted() {
console.log(this.data, this.minCardHeight,"data from cardgallblock.vue")
console.log(
      this.srcWidth,
      this.$vuetify.breakpoint.name,
      "cols breakpoint1"
    );
    console.log(
      this.$parent.$el.clientWidth,
      this.$vuetify.breakpoint.thresholds,
      "test"
    );

    this.srcSize = this.$refs.divWidth.clientWidth;
    this.$nextTick(() => {
      window.addEventListener("resize", this.divWidth);
    });
    window.addEventListener("resize", this.divWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.divWidth);
  },
  components: { CardGalleryBlockCard },
  computed: {
    contentData() {
      //filters the external_link and internal_page_link from card gallery data
      return this.data.gallery_items.filter(item => item.type !== "external_link" && item.type !== "internal_page_link" );
    },
    minCardHeight(){
      if(this.data.min_card_height != null){
        console.log(this.data.min_card_height, "mincard")
        return this.data.min_card_height;
      }else return undefined
    },
    colsBreakpoint() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.xs)
      ) {
        return 12;
      } else if (
        this.$vuetify.breakpoint.name == "sm" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.sm)
      ) {
        return 6;
      } else if (
        this.$vuetify.breakpoint.name == "md" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.md)
      ) {
        return 4;
      } else if (
        this.$vuetify.breakpoint.name == "lg" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.lg)
      ) {
        return 3;
      } else {
        return 3;
      }
    },
  },
  methods: {
    divWidth() {
      this.srcSize = this.$parent.$el.clientWidth;
      console.log(
        this.srcSize,
        this.$parent.$el.clientWidth,
        this.$vuetify.breakpoint.name,
        "fr divWidth"
      );
    },
    openGallery(index, card) {
      console.log("gallery open", "curr index", index, card);
      if(card.type !== 'external_link' && card.type !== "internal_page_link"){
        this.startIndex = this.contentData.findIndex(item => item.id == card.id)
      }else{
      this.startIndex = index;
      }
      this.showGallery = true;
    },
    closeGallery() {
      this.showGallery = false;
    },
    downloadDocument: function (url, label) {
      console.log(label);
      window.open(url);
      // axios.get(url, { responseType: 'blob' })
      // .then(response => {
      //   const blob = new Blob([response.data], { type: 'application/pdf' })
      //   const link = document.createElement('a')
      //   link.href = URL.createObjectURL(blob)
      //   link.download = label
      //   link.click()
      //   URL.revokeObjectURL(link.href)
      // }).catch(console.error)
    },
  },
  watch: {
    colsBreakpoint(newVal) {
      console.log(newVal, "cols breakpoint");
    },
  },
};
</script>

<style scoped>
.child-lightbox-container {
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  z-index: 100;
  /* pointer-events: none; */
}
.gallery {
  pointer-events: all;
}
.fitters {
  max-width: fit-content;
}
</style>