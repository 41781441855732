<template>
  <div class="panorama noscrollbars">
    <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      :tileUrl="lightboxContent"
      @closed="closeLightbox()"
      tabindex="0"
    />

    <Marzipano
      v-if="startingPanorama && panoramas.length"
      :tileUrl="tileUrl"
      :multiple-scenes="[startingPanorama, ...panoramas]"
      :data="startingPanorama && startingPanorama.panorama"
      :ready="autoRotate"
      :sasKey="sasKey"
      @walkthroughReady="walkthroughLoaded"
      @loaded="panoLoaded"
      @view="activeSceneView = $event"
      @scenes="handleScenes($event)"
      ref="Marzipano"
      :minYaw="minYaw"
      :maxYaw="maxYaw"
      :rectConfigEnabled="rectConfigEnabled"
      :active-scene="activeScene"
      :active-scene-view="activeSceneView"
      :initialViewParameters="initialViewParameters"
      @initialViewParameters="initialViewParameters = $event"
      tabindex="-1"
    >
      <!-- <div v-for="(child, i) in parsedChildContent" :key="activeScene ? `${i}-${activeSceneIndex}`: i">
        <MarzipanoRectHotspot
          v-if="child.type === 'virtualroomcontent'"
          :index="i"
          :key="activeScene ? `${activeSceneIndex}-${i}` : i"
          :yaw="child.position_data.yaw"
          :pitch="child.position_data.pitch"
          :rotX="child.position_data.rotX"
          :rotY="child.position_data.rotY"
          :rotZ="child.position_data.rotZ"
          :width="child.position_data.width"
          :height="child.position_data.height"
          :thumbnail="child.thumbnail"
          :scene="activeScene || scene"
          :arialabel="getAriaLabel(child)"
          :title="getChildTitle(child)"
          :icon="child.menu_icon"
          :clickable="child.clickable"
          :transparent="child.transparent_background"
          :lightboxActive="lightboxActive"
          :activeSceneIndex="startingPanorama.id"
          @click="clickChild(child, null)"
          @focus="onFocus()"
          :isMoving="isMoving"
          :movementSpeed="movementSpeed"
          ref="rectHotspot"
        />
      </div> -->

      <div
        v-for="(child, i) in parsedPanoramaContent"
        :key="activeScene ? `${i}-${activeSceneIndex}` : i"
      >
        <MarzipanoRectHotspot
          :index="i"
          :key="
            activeScene
              ? `panorama-${activeSceneIndex}-${startingPanorama.id}-${i}`
              : i
          "
          :yaw="child.position_data.yaw"
          :pitch="child.position_data.pitch"
          :rotX="child.position_data.rotX"
          :rotY="child.position_data.rotY"
          :rotZ="child.position_data.rotZ"
          :width="child.position_data.width"
          :height="child.position_data.height"
          :thumbnail="child.thumbnail"
          :scene="activeScene || scene"
          :activeSceneIndex="startingPanorama.id"
          :arialabel="getAriaLabel(child)"
          :title="getChildTitle(child)"
          :icon="child.menu_icon"
          :clickable="child.clickable"
          :transparent="child.transparent_background"
          :lightboxActive="lightboxActive"
          @click="clickChild(child, i)"
          @focus="onFocus()"
          :isMoving="isMoving"
          :movementSpeed="movementSpeed"
          ref="rectHotspot"
        />
      </div>
    </Marzipano>
    <PanoramaLoading class="pano" v-if="walkthroughLoading"/>

    <div class="button-panel ignore-mouse pt-7 pr-5">
      <v-container class="pt-0 pb-0">
        <v-row>
          <v-btn
            tabindex="-1"
            fab
            small
            class="use-mouse"
            @click="welcomeOpened()"
            data-testid="infoButton"
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <PageHeaderCard :transparent="true"> </PageHeaderCard>

    <img
      v-if="secondaryLogo !== undefined"
      :src="secondaryLogo"
      class="secondary-logo-panel ignore-mouse"
    />
    <!-- 
        <v-dialog persistent max-width="640px" v-model="showLanding">
          <v-card class="welcome_lightbox" style="overflow: hidden">
            <FocusLoop :is-visible="showLanding" :auto-focus="true">
              <v-card-text class="pt-5 pb-0">
                <v-container class="pt-0 pb-0">
                  <v-row
                    v-for="(block, i) in startingPanorama.landing_streamfield"
                    :key="i"
                  >
                    <v-col>
                      <StreamfieldBlock :block="block" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-container class="pt-0 pb-0">
                  <v-row justify="center">
                    <v-col
                      v-for="(block, i) in startingPanorama.landing_shortcuts"
                      :key="i"
                      cols="6"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                    >
                      <PageShortcutBlock
                        :icon="block.value.page.menu_icon"
                        :title="block.value.caption"
                        @click="shortcutClicked(block.value.page.detail_url)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </FocusLoop>
          </v-card>
        </v-dialog> -->

    <!-- Added Line (will delete later) -->
    <!-- <v-dialog v-model="accesibilityModal" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              role="navigation"
              aria-label="Secondary"
              v-bind="attrs"
              v-on="on"
              elevation="2"
              fab
              small
              raised
              rounded
              fixed
              left
              top
              style="left: 76px; top: 29px"
              >A</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Accesibility Mode
            </v-card-title>
            <div v-for="(child, i) in startingPanorama.child_content" :key="i">
              <v-list-item
                elevation="2"
                v-on:click="clickChild(child), (accesibilityMode = true)"
                >{{ getChildTitle(child) }}</v-list-item
              >
            </div>
            <v-divider></v-divider>
            <v-btn
              tabindex="-1"
              v-on:click="(accesibilityMode = false), (accesibilityModal = false)"
              >Close</v-btn
            >
          </v-card>
        </v-dialog> -->

    <v-dialog
      content-class="accessibility-lightbox"
      persistent
      scrollable
      v-model="accessibilityModalFlag"
      ref="accessibilityLightbox"
    >
      <template v-slot:default="dialog">
        <v-card height="100%">
          <FocusLoop :is-visible="activeTrap">
            <v-card-title class="text-h5 accessibility-header lighten-2">
              Accessibility Mode
            </v-card-title>
            <v-tabs>
              <v-tab>Explore the Room</v-tab>
              <v-tab-item>
                <v-list-item
                  v-if="accessibilityFlag"
                  key="1"
                  link
                  class="accessibility-btn"
                  @click="
                    closeAccessibilityLightbox();
                    $emit('showLanding', 'read-welcome');
                  "
                  >Read Welcome Message</v-list-item
                >
                <v-list-item
                  v-for="(child, i) in startingPanorama.panorama_child_content"
                  :key="accessibilityFlag ? i + 1 : i"
                  link
                  class="accessibility-btn"
                  @click="clickChild(child), (dialog.value = false)"
                  >{{ getChildTitle(child) }}</v-list-item
                >
              </v-tab-item>
              <v-tab>Site Navigation </v-tab>
              <v-tab-item>
                <v-list-item
                  v-for="(menuItem, i) in siteStructure"
                  :key="i"
                  :to="menuItem.pathname"
                  link
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>{{ menuItem.menu_icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                </v-list-item></v-tab-item
              >
            </v-tabs>
            <v-divider></v-divider>
            <v-btn
              class="accessibility-btn close-accessibility-btn"
              @click="closeAccessibilityLightbox(), (dialog.value = false)"
              >Close</v-btn
            >
          </FocusLoop>
        </v-card>
      </template>
    </v-dialog>

    <PanoramaInstructions v-show="showInstructions" />
  </div>
</template>

<script>
import Marzipano from "../../components/panorama/Marzipano.vue";
import PanoramaLoading from "../../components/panorama/PanoramaLoading.vue";
import MarzipanoRectHotspot from "../../components/panorama/MarzipanoRectHotspot";
import PanoramaInstructions from "../../components/panorama/PanoramaInstructions";
// import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
// import PageShortcutBlock from "../../components/blocks/pageShortcutBlock.vue";
import PageHeaderCard from "../../components/page/pageHeaderCard.vue";
import { FocusLoop } from "@vue-a11y/focus-loop";
import * as arrowKeyNavigation from "arrow-key-navigation";
//enables next & previous tab style DOM navigation using arrow keys
arrowKeyNavigation.register();

/*
    document.body.addEventListener("keydown", function (e) {
      console.log("Key: " + e.key); 
      if (e.key == "ArrowRight") {   
        window.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "Tab",
          })
        );
      } else if (e.key == "ArrowLeft") {   
        window.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "Tab",
            shiftKey: true
          })
        );
      }
    });
    */

export default {
  name: "TestRoom",
  props: ["pageData", "pageChildren", "accessibilityStream"],
  data: () => ({
    panorama: "/engagement_room.json",
    lightboxData: undefined,
    scene: undefined,
    lastYaw: undefined,
    lastPitch: undefined,
    lastFov: undefined,
    useLastLocation: false,
    lightboxReady: false,
    showLanding: Boolean,
    showInstructions: false,
    instructionsShown: false,
    autoRotate: false,
    consoleYaw: undefined,
    consolePitch: undefined,
    formYaw: undefined,
    formPitch: undefined,
    lightboxActive: undefined,
    currentFocusIndex: 0,
    currentActiveHotspot: undefined,
    currentActiveAccessibilityMenuItem: undefined,
    isMouseControl: false,
    isMoving: false,
    movementSpeed: 600,
    accessibilityModalFlag: false,
    accessibilityFlag: false,
    activeTrap: true,
    lightbox2Active: false,

    scenes: [],
    panoramas: [],
    childInstances: [],
    activeScene: null,
    activeSceneView: null,
    initialViewParameters: null,
    activeSceneIndex: null,
    selectedChild: null,
    startingPanorama: null,
    walkthroughLoading: true,
  }),
  components: {
    Marzipano,
    MarzipanoRectHotspot,
    PanoramaInstructions,
    PanoramaLoading,
    // StreamfieldBlock,
    // PageShortcutBlock,
    PageHeaderCard,
    FocusLoop,
  },
  created() {
    this.$root.$refs.engagementRoom = this;
  },
  mounted: async function () {
    // this.accessibilityFlagChecker();

    // console.log(this.pageData, "minyaw");
    // console.log("Data from Engagement", this.pageData);

    // //If we're not loading the root engagement room, hide the landing page
    // if (!this.isRootPage()) this.showLanding = false;
    // //check the current status of accessibility mode

    // if (this.startingPanorama.landing_shortcuts && this.startingPanorama.landing_shortcuts.length >= 1) this.showLanding = true;
    // else this.showLanding = false;

    // // Saves popup state to popup landing
    // if (sessionStorage.getItem("showLanding") == "false") {
    //   this.showLanding = false;
    // } else if (this.startingPanorama.landing_shortcuts && this.startingPanorama.landing_shortcuts.length >= 1)
    //   this.showLanding = true;

    await this.fetchPanoramas(
      this.pageData.starting_pano.id,
      this.pageData.panorama_child_content
    );
  },

  computed: {
    parsedPanoramaContent() {
      return [
        // ...this.childInstances.reduce((prev, curr) => {
        //   prev.push(...curr.panorama_child_content);
        //   return prev;
        // }, []),
        ...(this.startingPanorama && this.startingPanorama.child_content),
      ];
    },
    parsedChildContent() {
      return [
        // ...this.childInstances.reduce((prev, curr) => {
        //   prev.push(...curr.child_content);
        //   return prev;
        // }, []),
        ...(this.startingPanorama && this.startingPanorama.child_content),
      ];
    },
    lightboxContent() {
      if (this.$store.state.wagtail.currentPopup === undefined) return [];
      else return this.$store.state.wagtail.currentPopup.content;
    },
    tileUrl() {
      return (
        this.startingPanorama.panorama &&
        this.startingPanorama.panorama.tiles.split("?")[0]
      );
    },
    currentPath() {
      return this.$route.path;
    },
    sasKey() {
      return (
        this.startingPanorama.panorama && this.startingPanorama.panorama.sas_key
      );
    },
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        item.depth = item.meta.html_url.split("/").length - 4;
        let url = new URL(item.meta.html_url);
        item.pathname = url.pathname;
        item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
        siteStructure.push(item);
      }
      return siteStructure;
    },
    secondaryLogo() {
      return this.$store.state.wagtail.siteSettings.secondary_logo;
    },
    minYaw() {
      if (this.startingPanorama.panorama.min_yaw !== undefined)
        return this.startingPanorama.panorama.min_yaw;
      return -181;
    },
    maxYaw() {
      if (this.startingPanorama.panorama.max_yaw !== undefined)
        return this.startingPanorama.panorama.max_yaw;
      return 181;
    },
    rectConfigEnabled() {
      return this.startingPanorama.title === "panoconfig";
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    walkthroughLoaded() {
      this.walkthroughLoading = false;
    },
    async handleScenes(scenes) {
      this.scenes = scenes;
      const _sceneIndex = this.scenes.findIndex(
        (scene) =>
          new URL(scene.item.meta.html_url).pathname === this.$route.query.scene
      );
      console.log({ something: _sceneIndex });
      const sceneData = this.scenes[_sceneIndex];
      this.startingPanorama = sceneData.item;
      this.activeScene = sceneData.scene;
      this.activeSceneView = sceneData.view;
      this.initialViewParameters = sceneData.initialViewParameters;
      this.activeSceneIndex = _sceneIndex;
      if (this.$route.query.hotspot) {
        await this.$nextTick();
        this.clickChild({}, -1, this.$route.query.hotspot);
      }
    },
    async fetchPanoramas(startPanoramaId, panoramaChildren) {
      let _panoramaChildren = panoramaChildren;
      this.startingPanorama = await this.$store.dispatch(
        "wagtail/getStartPanorama",
        startPanoramaId
      );

      !_panoramaChildren &&
        (_panoramaChildren = this.startingPanorama.panorama_child_content);

      const data = await Promise.all(
        _panoramaChildren.map(async (item) => {
          return this.$store.dispatch("wagtail/getStartPanorama", item.id);
        })
      );
      // console.log({ data }, "xczxc");
      // const _data = await Promise.all(
      //   data.map(async (item) => {
      //     return this.$store.dispatch(
      //       "wagtail/getStartPanorama",
      //       item.panorama_children ? item.panorama_children.id : item.room_details.id
      //       // new URL(item.meta.html_url).pathname
      //     );
      //   })
      // );

      this.childInstances = data;
      this.panoramas = data;
      // console.log({
      //   "this.panoramas": this.panoramas,
      //   _data,
      //   asd: this.startingPanorama.panorama_child_content,
      // });
    },
    welcomeOpened() {
      this.$emit("showLanding");
    },
    onFocus() {
      this.isMoving = true;
    },
    getChildTitle(child) {
      if (child.icon_only) return "";
      return child.title;
    },
    getAriaLabel(child) {
      if (child.accessible_label) {
        return child.accessible_label;
      } else {
        return child.title;
      }
    },
    panoLoaded(scene) {      
      this.scene = scene;
      this.checkZoomToChild();
    },
    welcomeClosed() {
      this.showLanding = false;
      this.displayInstructions();
      if (this.accessibilityMode == true) {
        this.accessibilityModalFlag = true;
      } else {
        this.accessibilityModalFlag == false;
      }
    },
    displayInstructions() {
      this.showInstructions = true;
      this.instructionsShown = true;
      let self = this;
      setTimeout(function () {
        self.showInstructions = false;
        self.autoRotate = true;
      }, 3000);
    },
    async clickChild(child, i, hotspot) {
      console.log({ child, hotspot }, "hotspot child");

      let isMoving = true;

      let zoomData = child.position_data;

      var destinationViewParameters = {
        yaw: zoomData.yaw,
        pitch: zoomData.pitch,
        fov: (40 * Math.PI) / 180,
      };

      var options = {
        transitionDuration: 1500,
      };

      this.scene.lookTo(destinationViewParameters, options);

      if (child.walkthrough_step) {
        // const data =  await this.$store.dispatch(
        //   "wagtail/getStartPanorama",
        //   child.id
        // );
        // await this.fetchPanoramas(child.id)
        // if (this.activeScene) {
        //   this.activeScene.hotspotContainer().listHotspots().forEach(item => {
        //     this.activeScene.hotspotContainer().destroyHotspot(item)
        //   })

        // } else {
        //   this.scene.hotspotContainer().listHotspots().forEach(item => {
        //     this.scene.hotspotContainer().destroyHotspot(item)
        //   })

        // }

        setTimeout(() => {
          const _sceneIndex = this.scenes.findIndex(
            (scene) => scene.item.id === child.walkthrough_step.id
          );
          console.log({ something: _sceneIndex });
          const sceneData = this.scenes[_sceneIndex];
          this.startingPanorama = sceneData.item;
          this.activeScene = sceneData.scene;
          this.activeSceneView = sceneData.view;
          this.initialViewParameters = sceneData.initialViewParameters;
          this.activeSceneIndex = _sceneIndex;

          const path = this.$route.path;
          this.$router.push(
            `${path}?scene=${new URL(sceneData.item.meta.html_url).pathname}`
          );
        }, options.transitionDuration);

        return;
      }

      // Track which hotspot or accessibility menu item has been most recently clicked. used later to return forcus to the right place.
      let currentUILocation = document.activeElement;
      if (
        currentUILocation.id == document.querySelectorAll("#hotspot-img")[0].id
      ) {
        this.currentActiveHotspot = currentUILocation;
        this.isMouseControl = false;
      } else if (currentUILocation.classList[0] == "v-image") {
        //reset focus location storage if mouse is used.
        this.currentActiveHotspot = undefined;
        this.currentActiveAccessibilityMenuItem = undefined;
        this.isMouseControl = true;
      } else if (currentUILocation.classList[0] == "accessibility-btn") {
        this.currentActiveAccessibilityMenuItem = currentUILocation;
        this.isMouseControl = false;
      } else {
        console.log(
          "Opening lightbox NO MATCH, new access method detected. update focus tracking script"
        );
      }

      //track previous camera position and hide the accessibility menu before opening the hotspot lightbox

      this.lastYaw = this.scene.view().yaw();
      this.lastPitch = this.scene.view().pitch();
      this.lastFov = this.scene.view().fov();
      this.useLastLocation = true;

      //Open URL
      this.checkZoomToChild();
      const path = this.$route.path;
      // console.log({path, qwe:new URL(child.html_url).pathname});
      // const childParsedUrl = new URL(child.html_url).pathname.split('/')[2]
      // this.$router.push(`${path}/${childParsedUrl}`);
      const hotpostUrl = hotspot || new URL(child.html_url).pathname;
      const scene = this.$route.query.scene;
      this.$router.push(
        `${path}?hotspot=${hotpostUrl}${scene ? `&scene=${scene}` : ""}`
      );
      this.selectedChild = child;

      const data = await this.$store.dispatch(
        "wagtail/getPageDetails",
        hotpostUrl
      );
      console.log({ data });
      this.getLightboxData(data);
    },

    getLightboxData(child) {
      let id = child.id;
      let zoomData = child.position_data;
      this.lightboxReady = false;
      //TODO: I don't like using the store for this - should call the API service directly
      this.$store.dispatch("wagtail/getPopup", id);

      var destinationViewParameters = {
        yaw: zoomData.yaw,
        pitch: zoomData.pitch,
        fov: (40 * Math.PI) / 180,
      };

      var options = {
        transitionDuration: 1500,
      };

      this.scene.lookTo(destinationViewParameters, options);
      // Might be useful chose not to remove
      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      // var bodyElement = document.querySelector("body");
      // bodyElement.scroll(0, 0);

      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      let self = this;
      window.setTimeout(function () {
        self.lightboxReady = true;
        self.lightbox2Active = true;
      }, 1250);
    },

    closeLightbox() {
      //Updates trackers for hotspot status and reopens the accessibility menu if in accessibility mode.
      //this.lightboxActive = false;
      const path = this.$route.path;
      const scene = this.$route.query.scene;
      this.$router.push(`${path}${scene ? `?scene=${scene}` : ""}`);

      this.$store.commit("wagtail/getPopupSuccess", undefined);
      //Tracks that the camera is moving so that keyboard controls cannot override transition animation
      this.isMoving = true;

      // If we've flagged to use the last location, use it and clear the flag
      if (this.useLastLocation === true) {
        destinationViewParameters = {
          yaw: this.lastYaw,
          pitch: this.lastPitch,
          fov: this.lastFov,
        };
        this.useLastLocation = false;
      } else {
        var destinationViewParameters = {
          yaw: this.scene.view().yaw(),
          pitch: 0.0,
          fov: (60 * Math.PI) / 180,
        };
      }

      var options = {
        transitionDuration: this.movementSpeed,
      };

      // Display the instructions if they've not been displayed before
      // This is to handle use cases where a user has navigated straight
      // to some lightbox content, and hasn't come via the traditional entry point

      if (this.instructionsShown === false) this.displayInstructions();
      this.scene.lookTo(destinationViewParameters, options);

      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      // var bodyElement = document.querySelector("body");
      // bodyElement.scroll(0, 0);
      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      //use stored values to return focus to the correct previous location in virtual room or accessibility menu
      const elements = document.querySelectorAll("#hotspot-img");

      if (
        this.currentActiveHotspot != undefined &&
        this.accessibilityMode == false
      ) {
        // Moves focus to previous hotspot location in virtual room
        this.currentActiveHotspot.focus();
      } else {
        if (this.accessibilityMode == false) {
          //Moves focus Back to the first hotspot if not using a mouse"
          if (this.isMouseControl == false) {
            elements[0].focus();
          }
        } else {
          if (this.currentActiveAccessibilityMenuItem != undefined) {
            //Moves focus Back to the previous accessibility menu item
            this.$nextTick(() => {
              this.currentActiveAccessibilityMenuItem.focus();
            });
          }
        }
      }
    },

    checkZoomToChild() {
      if (
        this.startingPanorama &&
        this.startingPanorama.meta.type ===
          "engage_virtualrooms.EngageWalkthrough"
      ) {
        this.getLightboxData(this.selectedChild);
        return;
      }

      let currPath = window.location.pathname;
      let pagePath = new URL(this.startingPanorama.meta.html_url).pathname;
      console.log("BEFORE IF");
      //If we're not on the root page
      if (pagePath !== undefined && currPath !== undefined) {
        pagePath = pagePath.trimRight("/");
        currPath = currPath.trimRight("/");

        console.log("1 IF");
        // If the requested path is not the same as the current page path, then we may be requesting a child object
        if (pagePath !== currPath) {
          // Search the children for a valid child
          console.log("2 IF");
          for (let child of this.startingPanorama.child_content) {
            let childPath = new URL(child.html_url).pathname;
            if (childPath !== undefined) {
              console.log(childPath, "child path from EngagementRoom");
              childPath = childPath.trimRight("/");

              // We've found the right child, let's zoom to it and open the lightbox
              if (childPath === currPath) {
                console.log("99 IF");
                this.getLightboxData(child);
                return;
              }
            }
          }
        }
        // for (let anotherChild of this.startingPanorama.panorama_child_content) {
        //   let anotherChildPath = new URL(anotherChild.html_url).pathname;
        //   if (anotherChildPath !== undefined) {
        //     anotherChildPath = anotherChildPath.trimRight("/");
        //     console.log(anotherChildPath, "child path from EngagementRoom");
        //     if (anotherChildPath === currPath) {
        //       this.getLightboxData(anotherChild);
        //       return;
        //     }
        //   }
        // }
      }
      // TODO: if we don't find a valid child, reset the scene or reload the page...
    },
    isRootPage() {
      let currPath = this.currentPath;
      let pagePath = new URL(this.startingPanorama.meta.html_url).pathname;
      if (currPath === pagePath) return true;
      return false;
    },
    shortcutClicked(url) {
      //When we click a shortcut, we either route to that page, or if the shortcut is the current page we close the dialog
      let pagePath = new URL(url).pathname;
      let currPath = this.currentPath;

      if (currPath === pagePath) this.welcomeClosed();
      else this.$router.push(pagePath);

      const firstFocusableElement =
        document.querySelectorAll("#hotspot-img")[0];
      firstFocusableElement.focus();
    },
    lookTo() {
      this.consoleYaw = this.formYaw;
      this.consolePitch = this.formPitch;
      this.$refs.Marzipano.lookToConsole(this.consoleYaw, this.consolePitch);
    },
    accesibilityFlag() {
      return (this.accesibilityMode = true);
    },
    closeAccessibilityLightbox() {
      this.accessibilityFlag = false;
      this.accessibilityModalFlag = false;
      this.$store.commit(
        "wagtail/setAccessibiltyMode",
        this.accessibilityFlag,
        { root: true }
      );
      localStorage.setItem("accessibilityModeState", false);
      this.$emit("closeAccessibility");
    },
    accessibilityFlagChecker() {
      if (localStorage.getItem("accessibilityModeState") == "true") {
        this.accessibilityModalFlag = true;
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityModalFlag,
          { root: true }
        );
        console.log("ACCESS STATE TRUE");
      } else {
        this.accessibilityModalFlag = false;
        this.accessibilityFlag = false;
        console.log("ACCESS STATE FALSE");
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityModalFlag,
          { root: true }
        );
      }
    },
  },
  watch: {
    accessibilityStream(val) {
      this.accessibilityModalFlag = !val;
      this.accessibilityFlag = !val;
      this.$store.commit(
        "wagtail/setAccessibiltyMode",
        this.accessibilityModalFlag,
        { root: true }
      );
    },
    showLanding(val) {
      console.log(val, "showlanding");
    },
    isMoving() {
      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      //  var bodyElement = document.querySelector("body");
      //  bodyElement.scroll(0, 0);
      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      if ("activeElement" in document) {
        const elements = document.querySelectorAll("#hotspot-img");
        if (document.activeElement.id == elements[0].id) {
          this.currentActiveHotspot = document.activeElement;
        }
      }
      if (this.isMoving) {
        arrowKeyNavigation.unregister();
        window.onkeydown = () => false;
        window.setTimeout(() => {
          this.isMoving = false;
        }, this.movementSpeed);
      } else {
        arrowKeyNavigation.register();
        window.onkeydown = () => true;

        //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
        // window.scrollTo(0, 0);
        // var bodyElement = document.querySelector("body");
        // bodyElement.scroll(0, 0);
        for (let i = 0; i < panoramas.length; i++) {
          panoramas[i].scroll(0, 0);
          console.log("Pano " + i + " scrolling");
        }
      }
    },
    accessibilityMode(newValue) {
      if (newValue == true) {
        this.accessibilityModalFlag = true;
        this.accessibilityFlag = true;
        localStorage.setItem("accessibilityModeState", true);
      } else {
        this.accessibilityModalFlag = false;
        this.accessibilityFlag = false;
        const elements = document.querySelectorAll("#hotspot-img");
        localStorage.setItem("accessibilityModeState", false);
        this.$nextTick(() => {
          elements[0].focus();
        });
      }
    },
    currentPath() {
      this.checkZoomToChild();
      //Hide the landing page if the path has changed
      if (!this.isRootPage() && this.showLanding) this.showLanding = false;
      console.log(
        "path change.....",
        this.lightboxContent.length,
        this.lightboxReady
      );
    },
    islightboxActive() {
      if (this.islightboxActive == false) {
        if (this.accessibilityMode == true) {
          this.accessibilityModalFlag = true;
        } else {
          this.accessibilityModalFlag = false;
        }
      } else {
        this.accesibilityModal = false;
      }
    },
    lightboxContent(res) {
      console.log(
        "current popup val changed",
        this.$store.state.wagtail.currentPopup,
        res
      );
    },
    lightboxReady(res) {
      console.log("lightbox ready", res);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-sidebar {
  position: absolute;
  top: 0px;
  left: 0px;
  /* width: 344px; */
  width: 500px;
  height: 100%;
}

.map {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
}

.map-legend {
  display: none;
}

html {
  overflow: hidden !important;
}

.ignore-mouse {
  pointer-events: none !important;
}

.use-mouse {
  pointer-events: all;
}

.panorama {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.button-panel {
  position: absolute;
  top: 0px;
  right: 0px;
}

.attribution-panel {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

@media (max-width: 600px) {
  .button-panel {
    bottom: 0px;
  }
}

.secondary-logo-panel {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-height: 96px;
}

.lightbox-btn:focus-visible {
  outline: 4px dashed darkorange;
}
.accessibility-btn:focus-visible {
  outline: 4px dashed darkorange;
}

.accessibility-header {
  background-color: black;
  color: white;
}
::v-deep .accessibility-lightbox {
  min-width: 300px;
  width: 25vw;
  &.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 70%;
  }
}
</style>
