var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.validIcon),expression:"validIcon"}],ref:"icon",staticClass:"icon-div"},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(true || (_vm.showTooltip && !_vm.debug)),expression:"true || (showTooltip && !debug)"}],staticClass:"hotspot-hint fluid pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"text-center pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('ConcentricCircles',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTooltip && !_vm.isCentered),expression:"!showTooltip && !isCentered"}],staticClass:"hotspot-circle"})],1),_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:((!_vm.showTooltip && !_vm.isCentered) || _vm.title === ''),expression:"(!showTooltip && !isCentered) || title === ''"}],class:!_vm.showTooltip
                ? 'pa-0 hotspot-title hotspot-icon'
                : 'pa-1 hotspot-title hotspot-icon',attrs:{"dark":"","color":"secondary","rounded":"pill"}},[_c('v-icon',{staticClass:"pa-3"},[_vm._v(_vm._s(_vm.icon))])],1)],1),(_vm.title !== '')?_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip || _vm.isCentered),expression:"showTooltip || isCentered"}],class:_vm.showTooltip
                ? 'pa-1 hotspot-title hotspot-icon'
                : 'pa-0 hotspot-title hotspot-icon',style:(_vm.cardStyles),attrs:{"dark":"","color":"secondary","rounded":"pill"}},[_c('v-card-subtitle',{staticClass:"pa-2 ml-2 mr-2 white--text"},[_c('v-icon',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)],1)],1):_vm._e(),(_vm.showBubble)?_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('div',{staticClass:"bubble",style:(`top: -${_vm.chatHeight}px`),on:{"focus":function($event){_vm.showBubble = true},"mouseover":function($event){_vm.showBubble = true}}},[_c('p',{on:{"focus":function($event){_vm.showBubble = true},"mouseover":function($event){_vm.showBubble = true}}},[_vm._v(" "+_vm._s(_vm.text)+" ")])])]):_vm._e()],1)],1)],1),_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('div',{ref:"rect",staticClass:"hotspot-bg hotspot-btn",style:({
        width: _vm.hotspotSizeString.width,
        height: _vm.hotspotSizeString.height,
      }),attrs:{"data-testid":'rectHotspot' + _vm.index,"id":"rectHotspot","alt":""},on:{"mouseenter":function($event){_vm.showBubble = true},"mouseleave":function($event){_vm.showBubble = false},"mousemove":function($event){_vm.showBubble = true}}},[_c('div',[(_vm.debug === true)?_c('v-img',{style:({
            width: _vm.hotspotSizeString.width,
            height: _vm.hotspotSizeString.height,
            opacity: 0.5,
          }),attrs:{"src":"/grid.png","tab-index":"-1","alt":"","id":"hotspot-img"}}):_c('v-btn',{style:({
            width: _vm.hotspotSizeString.width,
            height: _vm.hotspotSizeString.height,
          }),attrs:{"text":"","dark":"","aria-label":_vm.ariaLabel,"disabled":!_vm.clickable,"id":"hotspot-img","data-testid":'hotspotBtn' + _vm.index},on:{"click":_vm.clicked,"mousedown":_vm.mouseDown,"mousemove":_vm.mouseMove,"focus":_vm.onFocus}},[_c('div',{class:_vm.transparent ? 'img-no-background' : 'img-background',attrs:{"tabindex":"-1"}},[(_vm.hasThumbnail)?_c('v-img',{style:({
                width: _vm.hotspotSizeString.width,
                height: _vm.hotspotSizeString.height,
              }),attrs:{"src":_vm.thumbnail.file,"contain":"","tabindex":"-1","alt":""}}):_vm._e()],1),_vm._t("default")],2),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip && !_vm.debug && _vm.clickable),expression:"showTooltip && !debug && clickable"}],staticClass:"hotspot-overlay"},[_c('v-container',{staticClass:"background",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col')],1)],1)],1)])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }