<template>
  <div  justify="center">
    <a :href="link_url">
    <v-img :href="link_url" :src="url" :max-height="height" contain></v-img>
    </a>
  </div>
</template>

<script>
export default {
  name: "ImageLinkBlock",
  props: ['url', 'width', 'height', 'link_url'],
};

</script>

<style scoped>
</style>