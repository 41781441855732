<template>
  <LightboxContentLayout
    :accessibilityText="data.accessibility_text"
    :ariaDescription="ariaDescription"
    :captionText="data.caption"
    :accessibilityTextFlag="accessibilityTextFlag"
  >
    <video
      :aria-label="accessibilityLabel"
      id="lightboxvideo"
      class="lightbox-video"
      controls
      ref="videoRef"
      type="video/mp4"
      crossorigin="anonymous"
    >
      <source :src="videoAccessibility" 
        type="video/mp4" 
      />
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        :src="subtitle"
        default
      />
    </video>
  </LightboxContentLayout>
</template>

<script>
import LightboxContentLayout from "./LightboxContentLayout.vue";

export default {
  name: "LightboxVideoCard",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false,
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibility",
    "ariaDescription",
  ],
  components: { LightboxContentLayout },
  mounted() {
    console.log(this.data.subtitle, "data from videolb");
    const video = this.$refs.videoRef;

    video.addEventListener("loadeddata", () => {
      if(video.readyState >= 3) {
        if (this.data.autoplay) {
          video.play();
        }
      }
    })

    video.addEventListener("ended", (event) => {
      this.$emit("ended");
    });

    for (let i = 0; i < video.textTracks.length; i++) {
      video.textTracks[i].mode = "hidden";
    }
  },
  computed: {
    videoToggle() {
      return this.$store.state.map.videoToggle
    },
    video() {
      if (this.data && this.data.file) return this.data.file;

      if (this.data.video2D && this.data.video2D.file)
        return this.data.video2D.file;
      return undefined;
    },
    subtitle() {
      if (this.data && this.data.subtitle) return this.data.subtitle;

      if (this.data.video2D && this.data.video2D.subtitle)
        return this.data.video2D.subtitle;
      return undefined;
    },
    videoAccessibility() {
      if ((this.accessibilityMode && this.accessibilityVideoFlag)) {
        if (process.env.NODE_ENV === "development") {
          // Branching Videos don't have the full URL but Card Galleries Videos do
          const url = new URL(
            this.data.accessible_video,
            process.env.VUE_APP_BACKEND_URL.slice(0, -1)
          );

          return url;
        }
        return this.data.accessible_video
      }
      const accessibilityVideo = (this.data && this.data.video2DAccessibility &&
          this.data.video2DAccessibility.file)

      if (this.videoToggle && accessibilityVideo) {
        if (process.env.NODE_ENV === "development") {
            // Branching Videos don't have the full URL but Card Galleries Videos do
            const url = new URL(
              accessibilityVideo,
              process.env.VUE_APP_BACKEND_URL.slice(0, -1)
            );

            return url;
          }

        return accessibilityVideo
      }

      if (process.env.NODE_ENV === "development") {
        // Branching Videos don't have the full URL but Card Galleries Videos do
        const url = new URL(
          this.data.file || (this.data.video2D && this.data.video2D.file),
          process.env.VUE_APP_BACKEND_URL.slice(0, -1)
        );
        return url;
      }

      return this.data.file || (this.data.video2D && this.data.video2D.file);
    },
    accessibilityLabel() {
      if (this.data && this.data.file) return this.data.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    hasAccessibleText() {
      if (this.data && this.data.accessibility_text) return true;
      return false;
    },
    videoWidth() {
      if (this.accessibilityTextFlag) return 60 + "%";
      if (
        this.hasAccessibleText &&
        this.hasCaption &&
        this.accessibilityTextFlag == true
      )
        return 70 + "%";
      return 100 + "%";
    },
  },
  watch: {
    accessibilityVideoFlag(newValue) {
      console.log(newValue, "value changed video");
      var videoElement = document.getElementById("lightboxvideo");

      if (this.accessibilityVideoFlag) {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.accessible_video); // empty source
        videoElement.load();
      } else {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.file); // empty source
        videoElement.load();
      }
    },
    videoAccessibility: {
      handler () {
        const video = this.$refs.videoRef;
        video.load();
        video.play();
        video.addEventListener("ended", (event) => {
          this.$emit("ended");
        });
      },
      deep: true
    },
  },
  methods: {
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
  },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.lightbox-video {
  width: 100%;
  height: 100%;
}
</style>
