<template>
  <div>
    <v-alert
      border="left"
      class="ml-0 mr-0"
      type="info"
      text
      color="secondary"
      ><ParagraphBlock :html="text.content"></ParagraphBlock></v-alert
    >
  </div>
</template>

<script>

import ParagraphBlock from "./paragraphBlock.vue"

export default {
  name: "AlertBlock",
  props: ["text"],
  components: {ParagraphBlock}
};
</script>

<style scoped>
</style>