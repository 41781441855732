<template>
  <v-card flat max-width="500px" class="mx-auto">
    <v-card-text>
      <div class="d-flex flex-column align-center justify-center">
        <v-img
          class="rounded-lg"
          :src="data.image.file"
          width="280px"
          height="280px"
        ></v-img>

        <h6 class="text-h6 mt-4">{{ data.audio.title }}</h6>
        <ParagraphBlock :html="data.description"></ParagraphBlock>
      </div>
      <VuetifyAudio flat :file="data.audio.file" color="success" />
    </v-card-text>
  </v-card>
</template>

<script>
import VuetifyAudio from "@/components/VuetifyAudio.vue";
import ParagraphBlock from "../blocks/paragraphBlock.vue";
export default {
  components: {
    VuetifyAudio,
    ParagraphBlock,
  },
  props: {
    data: Object,
  },
};
</script>
<style lang="scss" scoped>
h6 {
  max-width: 380px;
}
</style>
