<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ data.panel_title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-select
          :items="data.selected_precinct"
          item-text="title"
          item-value="id"
          :placeholder="data.title"
          return-object
          v-model="selectedPrecinct"
          @change="handlePrecinctSelection"
        >
        </v-select>

        <!--
        <v-btn
          :disabled="!selectedPrecinct"
          class="mt-4"
          block
          @click="handleReset()"
        >
          Reset
        </v-btn>
        -->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      selectedPrecinct: null,
    };
  },
  computed: {
    precinct() {
      return this.$store.state.map.precinct;
    },
  },
  methods: {
    handlePrecinctSelection() {
      console.log("[PRECINCT]", this.selectedPrecinct);
      this.$store.commit("map/setPrecinct", this.selectedPrecinct);
      this.$store.commit("map/setGroupLocation", null);
      this.$store.commit("map/setRightSidebarZoomLevel", this.selectedPrecinct.precinct_zoom_level);
      this.$store.commit("map/setRenderLocation", null);
    },
    handleReset() {
      this.$store.commit("map/setPrecinct", null);
      this.$store.commit("map/setGroupLocation", null);
      this.$store.commit("map/setRightSidebarZoomLevel", null);
      this.$store.commit("map/setRenderLocation", null);
      this.selectedPrecinct = null;
    },
  },
  watch: {
    precinct() {
      if (this.precinct == null) {
        this.selectedPrecinct = null;
      } else {
        if (this.precinct.precinct_select != null) {
          this.selectedPrecinct = this.precinct.precinct_select;
          console.log("[PRECINCT BLOCK]", this.selectedPrecinct);
        } else {
          this.selectedPrecinct = this.precinct;
        }
        this.$root.$emit('clear-render-view-2d')
        this.$root.$emit('clear-render-layer')

      }
    },
  },
};
</script>
