<template>
  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="ariaDescription"
    :captionText="data.caption" :accessibilityTextFlag="accessibilityTextFlag">
    <!-- <v-btn v-show="answerValue" rounded darkemit class="lightbox-btn" color="secondary" @click="flushAnswer()">
      <v-icon>mdi-keyboard-backspace</v-icon> Back</v-btn> -->
    <div class="page-container" v-if="!answerValue">
      <v-card class="pa-4 ma-3">
        <h3 class="pt-1">{{ data.question }}</h3>
        <v-radio-group v-model="selectedItem.questionBlock" key="question">
          <v-radio v-for="(choice, index) in data.choices" :key="index" :label="choice.question" :value="index"
            class="pl-4">
          </v-radio>
        </v-radio-group>
        <div v-if="selectedItem.questionBlock !== null">
          <div v-for="(choiceContent, choiceContentIndex) in data.choices[
            selectedItem.questionBlock
          ].content" :key="choiceContentIndex">
            <h3 class="pt-1">{{ choiceContent.value.question }}</h3>
            <v-radio-group v-model="selectedItem.extQuestionBlock" key="extQuestion">
              <v-radio v-for="(answerBlock, index) in choiceContent.value.content"
                :key="`ext-${index}-${choiceContentIndex}`" :label="answerBlock.value.answer" :value="index" class="pl-4">
              </v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-card>
    </div>
    <div v-for="(item, index) in answerValue" :key="item.id">
      <LightboxImageCard v-if="item.type === 'image'" :data="item.value" :accessibilityTextFlag="accessibilityTextFlag"
        :ariaDescription="'accessibilityDescriptionImage' + index" />
      <LightboxVideo360Card v-if="item.type === 'video_360'" :data="item.value"
        :accessibilityTextFlag="accessibilityTextFlag" :accessibilityVideoFlag="accessibilityVideoFlag"
        :ariaDescription="'accessibilityDescriptionVideo' + index++" />
      <LightboxVideoCard v-if="item.type === 'video'" :data="item.value" :accessibilityTextFlag="accessibilityTextFlag"
        :accessibilityVideoFlag="accessibilityVideoFlag" :ariaDescription="'accessibilityDescriptionVideo' + index++" />
    </div>
  </LightboxContentLayout>
</template>

<script>
import LightboxContentLayout from "./LightboxContentLayout.vue";
import { View360, EquirectProjection, ControlBar } from "@egjs/vue-view360";
import "@egjs/vue-view360/css/view360.min.css";
import LightboxImageCard from './LightboxImageCard.vue';
import LightboxVideoCard from './LightboxVideoCard.vue';
import LightboxVideo360Card from './LightboxVideo360Card.vue'

export default {
  name: "QuestionsLightbox",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false,
    lightboxReady: false,
    resetAnswer: false,
    selectedItem: {
      questionBlock: null,
      extQuestionBlock: null,
      answerBlock: null,
    },
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibility",
    "ariaDescription",
  ],
  components: { LightboxContentLayout, View360, LightboxImageCard, LightboxVideoCard, LightboxVideo360Card },
  mounted() {
    console.log(this.answerValue , "answerValue @@@@ questions lightbox")
  },
  created() {
    //  this.projection = new EquirectProjection({
    //       src: this.selectedOption,
    //       video: true
    //     });
  },
  computed: {
    video() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    answerValue() {
      if (this.selectedItem.questionBlock !== null && this.selectedItem.extQuestionBlock !== null) {
        // return this.data.choices[this.selectedItem.questionBlock].content[this.selectedItem.extQuestionBlock]
        // Test Muna boy
        // this.resetAnswer = 
        return this.data.choices[this.selectedItem.questionBlock].content[0].value.content[this.selectedItem.extQuestionBlock].value.content
      }

    },
    // videoAccessibility() {
    //   if (this.accessibilityMode && this.accessibilityVideoFlag)
    //     return this.data.accessible_video;
    //   return this.data.file;
    // },
    accessibilityLabel() {
      if (this.data && this.data.file) return this.data.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
    // accessibilityMode() {
    //   return this.$store.state.wagtail.accessibilityMode;
    // },
    hasAccessibleText() {
      if (this.data && this.data.accessibility_text) return true;
      return false;
    },
    // videoWidth() {
    //   if (this.accessibilityTextFlag) return 60 + '%'
    //   if (this.hasAccessibleText &&
    //     this.hasCaption &&
    //     this.accessibilityTextFlag == true) return 70 + "%";
    //   return 100 + '%'
    // }
  },
  watch: {
    accessibilityVideoFlag(newValue) {
      console.log(newValue, "value changed video");
      var videoElement = document.getElementById("lightboxvideo");

      if (this.accessibilityVideoFlag) {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.accessible_video); // empty source
        videoElement.load();
      } else {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.file); // empty source
        videoElement.load();
      }
    },
    answerValue(newValue){
      console.log(newValue, '@@@@qlb')
      this.$emit('answer-val-changed', newValue)
    } 
  },
  methods: {
    flushAnswer() {
      this.resetAnswer = true
      this.selectedItem = {
        questionBlock: null,
        extQuestionBlock: null,
        answerBlock: null,
      };
    },
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
    testFunc() {
      console.log("fire command");
      this.$refs.viewer.addPlugins(new ControlBar());
    },
    // handleChange() {
    //   console.log(this.selectedOption);
    //   this.projection = new EquirectProjection({
    //     src: this.selectedOption,
    //     video: true,
    //   });
    //   console.log(this.projection);
    // },
    answerValue(res) {
      console.log(res, "results time")
      this.lightboxReady = true

    }
  },
};
</script>


<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* Adjust as needed */
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.view360-canvas {
  width: 1000px;
  height: 1000px;
}
</style>