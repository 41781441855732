<template>
  <v-card min-width="500px">
    <v-card-text class="pb-0">
      <v-row>
        <v-textarea readonly :value="JSON.stringify(changeData)"></v-textarea>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader class="pl-0">X Position</v-subheader>
          <v-slider
            v-model="yaw"
            class="align-center"
            :max="180"
            :min="-180"
            :step="0.1"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="yaw"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="yaw += 0.1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="yaw -= 0.1"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader class="pl-0">Y Position</v-subheader>
          <v-slider
            v-model="pitch"
            class="align-center"
            :max="180"
            :min="-180"
            :step="0.1"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="pitch"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="pitch += 0.1"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
              <v-btn icon @click="pitch -= 0.1"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-subheader class="pl-0">Width</v-subheader>
          <v-slider
            v-model="width"
            class="align-center"
            :max="2000"
            :min="5"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="width"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="width += 1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="width -= 1"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader class="pl-0">Height</v-subheader>
          <v-slider
            v-model="height"
            class="align-center"
            :max="2000"
            :min="5"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="height"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="height += 1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="height -= 1"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-subheader class="pl-0">X Rotation</v-subheader>
          <v-slider
            v-model="rotX"
            class="align-center"
            :max="90"
            :min="-90"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="rotX"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="rotX += 1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="rotX -= 1"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader class="pl-0">Y Rotation</v-subheader>
          <v-slider
            v-model="rotY"
            class="align-center"
            :max="90"
            :min="-90"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="rotY"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="rotY += 1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="rotY -= 1"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-subheader class="pl-0">Z Rotation</v-subheader>
          <v-slider
            v-model="rotZ"
            class="align-center"
            :max="180"
            :min="-180"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="rotZ"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              <v-btn icon @click="rotZ += 1"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="rotZ -= 1"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MarzipanoRectConfigurator",
  props: ["scene"],
  data: () => ({
    yaw: 0.0,
    pitch: 0.0,
    width: 100,
    height: 100,
    rotX: 0.0,
    rotY: 0.0,
    rotZ: 0.0,
    firstPos: undefined,
    secondPos: undefined,
  }),
  components: {},
  mounted: function () {
    // let self = this;
    // var view = this.scene.view()
    // var pano = this.scene.viewer().domElement()
    // pano.addEventListener("click", function (e) {
    //   // var clickPos = view.screenToCoordinates({ x: e.layerX, y: e.layerY });
    //   // self.positionClick(clickPos)
    // })
  },
  methods: {
    positionClick(yawPitch) {
      const lerp = (x, y, a) => x * (1 - a) + y * a;

      if (this.firstPos === undefined)
        // First click, just store and wait for second click
        this.firstPos = yawPitch;
      else {
        //Second click, find centre
        this.secondPos = yawPitch;

        let avgYaw = lerp(this.firstPos.yaw, this.secondPos.yaw, 0.5);
        let avgPitch = lerp(this.firstPos.pitch, this.secondPos.pitch, 0.5);
        this.yaw = avgYaw * (180 / Math.PI);
        this.pitch = avgPitch * (180 / Math.PI);

        this.firstPos = undefined;
        this.secondPos = undefined;
      }
    },
  },
  computed: {
    changeData() {
      let data = {
        yaw: (this.yaw * Math.PI) / 180.0,
        pitch: (this.pitch * Math.PI) / 180.0,
        width: this.width + "px",
        height: this.height + "px",
        rotX: this.rotX,
        rotY: this.rotY,
        rotZ: this.rotZ,
      };

      console.log(JSON.stringify(data));

      return data;
    },
  },
  watch: {
    changeData() {
      this.$emit("changed", this.changeData);
    },
  },
};
</script>

<style scoped>
.hotspot-bg {
  background-color: rgba(255, 0, 0, 0.5);
}
</style>