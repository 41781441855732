<template>
  <v-fade-transition >
  <v-container fill-height fluid class="background">
    <v-row align="center" justify="center">
      <v-col>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            size="50"
            color="secondary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
  </v-fade-transition>
</template>

<script>
export default {
  name: "Loading",
  data: () => ({
    items: [],
    select: undefined,
    search: undefined,
    loading: undefined,
    toggle_exclusive: 1,
  }),
};
</script>

<style scoped>

.background {
  background-color: rgba(255,255,255,0.5)
}
</style>