<template>
  <v-toolbar :dark="darkBackground ? true : false" :color="transparent ? 'transparent' : navBarColor " :elevation="transparent ? 0 : 2" :height="height" :class="transparent ? 'pass-thru' : 'clickable'">
    <v-app-bar-nav-icon
      class="clickable"
      @click="TOGGLE_HOME_NAVIGATION_DRAWER(!isHomeNavigationDrawerOpen)"
      aria-label="Open Main Navigation Menu"
    ></v-app-bar-nav-icon>

    <v-app-bar-nav-icon
      id="accessibility-menu-btn"
      class="clickable accessibility-menu-btn"
      aria-label="Enable All Accessibility Features"
      @click="accessibilityModeActive()"
    >
      <v-icon> mdi-human </v-icon>
    </v-app-bar-nav-icon>
    
    <v-img
      height="100%"
      :src="siteLogo"
      width="200px"
      contain
      position="left"
    ></v-img>

    <v-toolbar-items aria-label="secondary" role="navigation" v-if="!transparent" class="d-none d-md-flex">
        <v-btn
          text
          v-for="item in siteStructure"
          :aria-label="item.title"
          :target="item.meta.type !== 'engage_pages.GlobalRedirectPage' ? '' : '_blank'"
          :key="item.title"
          :to="item.pathname"
          >
            <v-icon v-show="item.depth == 0" dark >{{ item.menu_icon }}</v-icon>
            <span  v-show="item.depth > 0">{{ item.title }} </span>
        </v-btn>
      </v-toolbar-items>
  </v-toolbar>

</template>

<script>
import { mapActions, mapState } from "vuex";
import {isDark} from "../../util/color"
export default {
  name: "MenuLogoCard",
  props: ["data", "transparent", "height"],
  data: () => ({
    currentPath: "/",
    darkBackground: false,
  }),
  components: {},
  mounted (){
    console.log(this.siteStructure ,"data from eader")
    this.$store.dispatch("wagtail/getSiteStructure");
    this.currentPath = window.location.pathname

    // On mount, determine the menu and accessibility icon colors in the toolbar
    if (this.$store.state.wagtail.siteSettings.navbar_colour !== null) {
      this.darkBackground = isDark(this.$store.state.wagtail.siteSettings.navbar_colour)
    } else {
      this.darkBackground = isDark(this.$vuetify.theme.currentTheme.primary)
    }
  },
  computed: {
    ...mapState("toolbar", ["isHomeNavigationDrawerOpen"]),
    siteLogo() {
      return this.$store.state.wagtail.siteSettings.site_logo;
    },
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        //Keep Icon for L0 no text, keep text no icon for L1
        console.log(item, "test item")
        if (item.depth == 0 || item.depth == 1){
    
          item.depth = item.meta.html_url.split("/").length - 4;
          let url = new URL(item.meta.html_url);
          item.pathname = url.pathname;
          item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
          console.log(item,item.depth, "item depth")
          siteStructure.push(item);
        }
      }

      return siteStructure;
    },
    navBarColor(){
      if(this.$store.state.wagtail.siteSettings.navbar_colour !== null){
        return this.$store.state.wagtail.siteSettings.navbar_colour
      }
      return 'primary';
    }
  },
  watch: {
    navBarColor(newValue) {
      // On navbar color change, determine the menu and accessibility icon colors in the toolbar
      if(newValue !== 'primary') {
        this.darkBackground = isDark(newValue)
      } else {
        this.darkBackground = isDark(this.$vuetify.theme.currentTheme.primary)
      }
    }
  },
  methods: {
    ...mapActions("toolbar", ["TOGGLE_HOME_NAVIGATION_DRAWER"]),
        accessibilityModeActive() {
      console.log("activated");
      if (this.accessibilityMode == true) {
        this.accessibilityFlag = false;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      } else {
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      }
    },
  },
};
</script>

<style scoped>
.pass-thru {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.menu-btn {
  position: absolute;
  left: 0px;
  top: 0%;
  margin-top: 0px;
}

.site-logo {
  /* height:56px; */ 
}
</style>