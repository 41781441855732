<template>
    <v-expansion-panels variant="accordion" :value="item.open_by_default ? 0 : undefined">
      <v-expansion-panel>
        <v-expansion-panel-header>{{ item.panel_title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-container fluid class="pa-0">
            <span class="text-caption">{{ item.title }}</span>
            <v-select
              v-model="location"
              hide-details
              :items="item.selected_location"
              item-text="title"
              return-object
            >
            </v-select>
            <v-btn text x-small @click="location = null">Reset</v-btn>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      location: null,
      layersID: null,
      layersData: null,
    };
  },
  computed: {
    selectLocation() {
      return this.pageData.description.find(
        (item) => item.type === "selectLocation"
      );
    },
    currentPage() {
      return this.$store.state.wagtail.currentPage;
    },
    resetLocation () {
      return this.$store.state.map.resetLocation;
    },
  },
  watch: {
    resetLocation (value) {
      if (value) {
        this.location = null
        this.$store.commit('map/setResetLocation', false)
      }
    },
    location() {
      this.$store.commit("map/setSelectedLocation", this.location);
      console.log("TRACK", this.location)
      if (this.location != null) {
        let layerOptionsData = {};
        layerOptionsData[this.location.layers[0].layer_name] = true;
        let payload = { key: this._uid, data: layerOptionsData };
        this.layersData = layerOptionsData;
        this.layersID = this._uid;
        console.log("LOCATION payloadJS", payload);
        this.$store.commit("wagtail/setLayerOptions", payload);
      } else {
        // Invert the layerOptionsData values
        let invertedLayerOptionsData = {};
        Object.keys(this.layersData).forEach((key) => {
          invertedLayerOptionsData[key] = !this.layersData[key];
        });

        let payload = { key: this.layersID, data: invertedLayerOptionsData };
        console.log("Inverted LOCATION payloadJS", payload);
        this.$store.commit("wagtail/setLayerOptions", payload);
      }
    },
  },
};
</script>

<style></style>
