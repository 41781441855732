import Vue from 'vue';
import VueRouter from 'vue-router'

// import InteractiveMap from '../views/InteractiveMap.vue'
// import EngagementRoom from '../views/EngagementRoom.vue'
// import Documents from '../views/Documents.vue'
// import Gallery from '../views/Gallery.vue'
// import Faq from '../views/Faq.vue'
// import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
// import Profile from '../views/Profile.vue'
import WagtailPage from '../views/WagtailPage.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  // { name: 'Home', path: '/', redirect: '/interactive-map', meta: { label: 'Home', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'Interactive Map', path: '/interactive-map', component: InteractiveMap, meta: { label: 'Interactive Map', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'Engagement Room', path: '/engagement-room', component: EngagementRoom, meta: { label: 'Engagement Room', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'Documents', path: '/documents', component: Documents, meta: { label: 'Documents', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'Gallery', path: '/gallery', component: Gallery, meta: { label: 'Gallery', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'FAQ', path: '/faq', component: Faq, meta: { label: 'FAQ', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  // { name: 'Contact', path: '/contact', component: Contact, meta: { label: 'Contact', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  { name: 'Login', path: '/login', component: Login, meta: { label: 'Login', icon: 'mdi-login', needsLogin: false, hideOnLogin: false }  },
  { name: 'Logout', path: '/logout', component: Logout, meta: { label: 'Logout', icon: 'mdi-login', needsLogin: false, hideOnLogin: false }  },
  // { name: 'Profile', path: '/Profile', component: Profile, meta: { label: 'Profile', icon: 'mdi-login', needsLogin: true, hideOnLogin: false }  },
  { name: 'WagtailPage', path: '/:catchAll(.*)', component: WagtailPage, meta: { label: 'WagtailPage', icon: 'mdi-login', needsLogin: false, hideOnLogin: false }  },
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
  
  const authRequired = to.meta.needsLogin;
  const loggedIn = store.state.auth.status.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

// router.afterEach((to, from) => {
// });

export default router; 
