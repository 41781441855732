<template >
  <div>
    <LightboxVideo360Card :data="this.currentVideo" :key="this.currentVideoIndex" @ended="setNextVideo()" @loaded="() => {this.isVideoSet = false}"/>
  </div>
</template>
<script>

import LightboxVideo360Card from "./LightboxVideo360Card.vue"
export default {

  props: [
    'data'
  ],
  components: {
    LightboxVideo360Card
  },
  data: () =>({
    // For testing purposes
    // videos: ["http://localhost:8000/media/original_videos/video_01.mp4", "http://localhost:8000/media/original_videos/video_02.mp4", "http://localhost:8000/media/original_videos/video_03.mp4", ],
    currentVideoIndex: 0,
    currentVideo: null,
    isVideoSet: false,
  }),
  created() {
    this.currentVideo = this.data.videos[0]
  },
  methods: {
    setNextVideo() {
      if(this.currentVideoIndex < this.data.videos.length - 1 && !this.isVideoSet) {
        this.currentVideoIndex = this.currentVideoIndex + 1
        this.currentVideo = this.data.videos[this.currentVideoIndex]
        this.isVideoSet = true
      }
    }
  }
}
</script>
<style>
  
</style>