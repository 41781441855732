<template>
  <!-- <div v-html="parseHtml"></div> -->
  <component v-bind:is="transformed" v-bind="$props" />
  <!-- <dynamicHtml v-bind:is="transformed" v-bind="parseHtml"></dynamicHtml> -->
  <!-- <div><router-link to="/me">Hello</router-link></div> -->
</template>

<script>
import apiUrl from "../../services/api-url";
// import dynamicHtml from "./dynamicHtml.vue"

export default {
  name: "ParagraphBlock",
  props: ["html", "link_colour"],
  // components: {dynamicHtml},
  computed: {
    parseHtml() {
      // This section replaces <a> tags that reference the SPA, with vue-router compatible tags

      var root = document.createElement("div");
      var el = document.createElement("div");
      el.innerHTML = this.html;
      root.appendChild(el);

      const links = Array.from(el.getElementsByTagName("a"));
    

      for (const link of links) {
        var href = link.getAttribute("href");
          link.setAttribute("target", "_blank")
          link.setAttribute("rel", "nofollow noopener")
          
        if (this.link_colour){
          link.style.color = this.link_colour
        }

        // If the link is internal and not a PDF, create a router link element so the user routes to the URL
        if (href !== undefined) {
          if (href.startsWith(apiUrl()) && !href.endsWith('pdf')) {
            let url = new URL(href);
            var routerlink = document.createElement("router-link");
            routerlink.setAttribute("to", url.pathname);
            routerlink.innerText = link.innerText;
            link.parentNode.replaceChild(routerlink, link);
          }
        }
      }

      const embeds = Array.from(el.getElementsByTagName("embed"));

      for (const embed of embeds) {
        if (embed.getAttribute("embedtype") === "image") {
          var src = embed.getAttribute("src");
          var format = embed.getAttribute("format");
          if (src !== undefined) {
            var div = document.createElement("div");
            var image = document.createElement("img");
            div.appendChild(image);
            image.setAttribute("src", src);
            image.style.maxWidth = "100%";
            if (format === "fullwidth") {
              image.style.width = "100%";
              div.className = "text-center";
            }
            if (format === "left") div.className = "text-left";
            if (format === "right") div.className = "text-right";
            embed.parentNode.replaceChild(div, embed);
          }
        }
      }

      for (const h2 of Array.from(el.getElementsByTagName("h2"))) 
        h2.className = "text-h2 mb-6"
      
      for (const h3 of Array.from(el.getElementsByTagName("h3"))) 
        h3.className = "text-h3 mb-5"

      for (const h4 of Array.from(el.getElementsByTagName("h4"))) 
        h4.className = "text-h4 mb-4"

      for (const p of Array.from(el.getElementsByTagName("p"))) 
        p.className = "text-body-1 mb-4"

      for (const li of Array.from(el.getElementsByTagName("li"))) 
        li.className = "text-body-1 mb-4"
      
      for (const li of Array.from(el.getElementsByTagName("ul"))) 
        li.className = "text-body-1 mb-4"

      for (const strong of Array.from(el.getElementsByTagName("strong"))) 
        strong.className = "text-body-1 mb-4"

      return root.innerHTML;
    },
    transformed() {
      // Magic from here: https://forum.vuejs.org/t/dynamically-compile-router-link/7410
      return {
        template: this.parseHtml,
        props: this.$options.props,
      };
    },
  },
};
</script>

<style scoped>
.fullwidth-img {
  width: 100%;
}

>>> h2 {
  margin-bottom: 8px;
}

>>> h3 {
  margin-bottom: 8px;
}

>>> h4 {
  margin-bottom: 8px;
}
</style>