<template>
    <div>
        <img 
          name="rendered-image" 
          :src="data.location_render_image.file" 
          :alt="data.location_render_image.alternative_text"
          :style="{ overflow: 'hidden' }"
          height="100%" 
          width="100%" 
          v-on:load="clearLoading()"
          >
          <div v-for="hotspot in this.imageHotspots">
            <v-scale-transition origin="center center">
              <ConcentricCircles
                v-show="true"
                class="hotspot-circle-map-marker"
                :style="{
                  position: 'absolute',
                  top: hotspot.top + '%',
                  left: hotspot.left + '%',
                }"
              />
            </v-scale-transition>
            <v-btn
              class="details-button white--text secondary"
              :key="hotspot.title"
              :style="{
                position: 'absolute',
                top: hotspot.top + '%',
                left: hotspot.left + '%',
                pointerEvents: 'all'
              }"
              @mouseenter="showCircle = false"
              @mouseleave="showCircle = true"
              @click="() => handleClick(hotspot)"
            >
              <v-icon class="marker-icon" v-if="hotspot.menu_icon !== null">{{ hotspot.menu_icon }}</v-icon>
              <span class="d-inline-block text-center marker-title">{{ hotspot.title }}</span>
          </v-btn>
          </div>

    </div>
</template>
<script>


import ConcentricCircles from "../panorama/ConcentricCircles.vue";
export default {
  name: "RenderView2d",
  data: () => ({
    imageHotspots: [],
  }),
  props:["data"],
  components: {
    ConcentricCircles
  },

  async mounted() {
    try{

      const {items} = await this.$store.dispatch('wagtail/getPageChildren', this.data.id);

      let hotspotData =[]

      for(const item of items) {

        const imageHotspot = await this.$store.dispatch('wagtail/getPageById', item.id);
        hotspotData.push(imageHotspot)

      }

      this.imageHotspots = hotspotData;
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    handleClick(hotspot) {
      this.$store.dispatch("wagtail/setCurrentPopup", { content: hotspot.content});
      this.$root.$emit('open-lightbox')
    },
    clearLoading() {
      this.$root.$emit('clear-loading')
    }
  },
}
</script>

<style>

</style>
