<template>
  <v-container>
    <v-card style="text-align: center" flat>
      <v-card-text>
        <v-btn
          outlined
          icon
          class="ma-2"
          :color="color"
          @click.native="isPlaying ? playPause() : playPause()"
          :disabled="!audioLoaded"
        >
          <v-icon v-if="!isPlaying || paused">{{ playIcon }}</v-icon>
          <v-icon v-else>{{ pauseIcon }}</v-icon>
        </v-btn>
        <div
          class="d-inline-block h-auto w-auto relative"
          @mouseenter="showVolume = true"
          @mouseleave="showVolume = false"
        >
          <v-btn
            outlined
            icon
            class="ma-2"
            :color="color"
            @click.native="mute()"
            :disabled="!audioLoaded"
          >
            <v-icon v-if="!muted">{{ volumeHighIcon }}</v-icon>
            <v-icon v-else>{{ volumeMuteIcon }}</v-icon>
          </v-btn>

          <v-slider
            v-if="showVolume"
            v-model="playerVolume"
            max="1"
            step="0.01"
            min="0"
            class="volume"
            style="cursor: pointer"
          ></v-slider>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <span>{{ formatTime(currentTime) }}</span>
          <span>{{ formatTime(duration) }}</span>
        </div>
        <v-progress-linear
          ref="progressBar"
          :value="progress"
          height="8"
          rounded="xl"
          style="margin-top: 15px; margin-bottom: 15px; cursor: pointer"
          @click="seek"
          v-if="audioLoaded"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Howl } from "howler";

export default {
  props: {
    file: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    playIcon: {
      type: String,
      default: "mdi-play",
    },
    pauseIcon: {
      type: String,
      default: "mdi-pause",
    },
    stopIcon: {
      type: String,
      default: "mdi-stop",
    },
    refreshIcon: {
      type: String,
      default: "mdi-refresh",
    },
    downloadIcon: {
      type: String,
      default: "mdi-download",
    },
    volumeHighIcon: {
      type: String,
      default: "mdi-volume-high",
    },
    volumeMuteIcon: {
      type: String,
      default: "mdi-volume-mute",
    },
  },
  data() {
    return {
      sound: null,
      isPlaying: false,
      progress: 0,
      audioLoaded: false,
      progressInterval: null,
      backgroundAudioId: null,
      muted: false,
      showVolume: false,
      playerVolume: 0.5,
      currentTime: 0,
      duration: 0,
    };
  },
  mounted() {
    this.sound = new Howl({
      src: [this.file],
      volume: this.playerVolume,
      onload: () => {
        this.audioLoaded = true;
        this.duration = this.sound.duration();
      },
      onplay: () => {
        this.isPlaying = true;
        this.startProgressUpdates();
      },
      onpause: () => {
        this.isPlaying = false;
        this.stopProgressUpdates();
      },
      onend: () => {
        this.isPlaying = false;
        this.progress = 0;
        this.stopProgressUpdates();
      },
    });
  },
  methods: {
    mute() {
      this.muted = !this.muted;
      this.sound.mute(this.muted, this.backgroundAudioId);
    },
    playPause() {
      if (this.isPlaying) {
        this.sound.pause();
      } else {
        this.backgroundAudioId = this.sound.play();
      }
    },
    startProgressUpdates() {
      this.progressInterval = setInterval(() => {
        const duration = this.sound.duration();
        this.currentTime = this.sound.seek();
        this.progress = (currentTime / duration) * 100;
      }, 100); // Update every 100ms
    },
    stopProgressUpdates() {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    },
    seek(event) {
      const progressBarWidth = this.$refs.progressBar.$el.clientWidth;
      const clickX = event.offsetX;
      const newTime = (clickX / progressBarWidth) * this.sound.duration();
      this.sound.seek(newTime);

      // Immediately update progress bar
      this.progress = (newTime / this.sound.duration()) * 100;
      this.currentTime = newTime;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    },
  },
  watch: {
    playerVolume() {
      this.sound.volume(this.playerVolume);
    },
  },
  beforeDestroy() {
    this.sound.unload();
    this.stopProgressUpdates();
  },
};
</script>

<style scoped>
.v-progress-linear {
  cursor: pointer;
}

.volume {
  position: absolute;
  width: 150px;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
