<template >
  <div>
    <LightboxVideoCard :data="this.currentVideo" :key="this.currentVideoIndex" @ended="setNextVideo()"/>
  </div>
</template>
<script>

import LightboxVideoCard from "./LightboxVideoCard.vue"

export default {

  props: [
    'data'
  ],
  components: {
    LightboxVideoCard
  },
  data: () => ({
    // For testing purposes
    // videos: ["http://localhost:8000/media/original_videos/video_01.mp4", "http://localhost:8000/media/original_videos/video_02.mp4", "http://localhost:8000/media/original_videos/video_03.mp4", ],
    currentVideoIndex: 0,
    currentVideo: null,
  }),
  created() {
    this.currentVideo = this.data.videos[0]

  },
  methods: {
    setNextVideo() {
      if (this.currentVideoIndex < this.data.videos.length - 1) {
        this.currentVideoIndex = this.currentVideoIndex + 1
        this.currentVideo = this.data.videos[this.currentVideoIndex]
      }
    }
  }
}
</script>
<style></style>