<template>
  <div
    style="overflow: hidden"
    @keydown.up.exact.prevent="nextMarker()"
    @keydown.down.exact.prevent="prevMarker()"
  >
    <MapArc
      v-if="pageData !== undefined"
      ref="map"
      :legendOpacity="pageData.legend_opacity"
      :showLegend="pageData.show_legend"
      :basemapWidget="pageData.basemap_widget"
      :mapZoom="pageData.zoom"
      :cameraTilt="cameraTilt"
      :heading="heading"
      :mapMinZoom="pageData.minZoom"
      :mapMaxZoom="pageData.maxZoom"
      :mapCenter="[pageData.lng, pageData.lat]"
      :bottomLeftExtent="pageData.bottomLeftpoint"
      :topRightExtent="pageData.topRightpoint"
      :isRotationEnabled="pageData.disableMapRotation"
      :portalItemId="portalItemId"
      :portalItemType="portalItemType"
      :filterJson="$store.state.map.layerFilter"
      :layerOptions="layerOptions"
      :tokens="pageData.tokens"
      :selectedLayers="selectedLayers"
      :renderLayer="renderLayer"
      :renderView2d="renderView2d"
      @ready="mapReady()"
      @mapLoadStart="loading = true"
      @mapLoadEnd="loading = false"
      @mapMoved="mapMoved"
      @mapZoomed="mapZoomed"
      @resetTilt="cameraTilt = $event"
      tabindex="-1"
    >
      <div
        v-for="(item, i) in pageData.children"
        :key="pageData.id + 'page_' + i"
        @click="markerClicked(item)"
      >
        <!-- Create a HTML marker for each marker - note this needs to site alongside the map, and be passed the map component as a prop -->
        <MapMarkerArcHtml
          v-show="
            showMarkers &&
            item.min_marker_zoom <= currZoomLevel &&
            item.max_marker_zoom >= currZoomLevel
          "
          v-if="
            (item.type == 'mappage' && item.showMarker) ||
            item.type == 'precinctlocation' ||
            (item.type !== 'mappage' && item.lat !== null && item.lng !== null)
          "
          :id="i"
          :coordinates="[item.lng, item.lat]"
          :colour="secondaryColor"
          :title="item.title"
          :icon="item.menu_icon"
          :mapCenter="mapCenter"
          :mapArc="$refs.map"
          :arialabel="getAriaLabel(item)"
          :currZoomLevel="currZoomLevel"
          :markerType="item.type"
          :layerData="{ lat: item.lat, lng: item.lng }"
          :hotspotGroupData="item.hotspotGroup"
          :thumbnailImage="item.thumbnail_image"
          @keydown.up.exact.prevent="nextMarker()"
          @keydown.down.exact.prevent="prevMarker()"
        />
      </div>
    </MapArc>
    <MapStickyCard
      v-if="this.currStickyItem != undefined && colsBreakpoint !== false"
      :stickyItemData="this.currStickyItem"
    />

    <!-- <Searchbar class="searchbar" /> -->
    <Loading v-show="loading" class="loading" />
    <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      @closed="closeLightbox()"
    />
    <slot />
  </div>
</template>

<script>
import MapArc from "./MapArc.vue";
import MapMarkerArcHtml from "./MapMarkerArcHtml.vue";
import Loading from "./Loading.vue";
import LightboxGallery from "../lightbox/LightboxGallery.vue";
import MapStickyCard from "../../components/misc/MapStickyCard.vue";

export default {
  name: "Map",
  props: {
    pageData: Object,
    accessibilityZoom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedLayers: [],
    accessToken:
      "pk.eyJ1IjoiZ2hkLWlkcyIsImEiOiJja2ZveHVycHkwNXlrMnpydmh0dWhmaXV5In0.KK_IxnZ4H4-9eEOIaUjN8g",
    mapStyle: "mapbox://styles/ghd-ids/ckg1rthma03821amhn8m49xlo",
    mapCenter: [152.849434, -25.301246],
    popupCoordinates: [0, 0],
    // mapZoom: 15.51,
    mapMinZoom: 7,
    mapMaxZoom: 16,
    cameraTilt: 0,
    heading: 0,
    showPopup: false,
    popupText: "",
    loading: true,
    lightboxData: undefined,
    lightboxReady: false,
    accessibilityModal: false,
    counter_flag: 0,
    previousFocusItem: undefined,
    markerZoom: 0,
    markerVisible: true,
    zoomMarkerFlag: true,
    currZoomLevel: 0,
    transformedObject: {},
    renderLayer: undefined,
    renderView2d: undefined,
    showMarkers: true,
    currStickyItem: null,
  }),
  components: {
    MapArc,
    MapMarkerArcHtml,
    Loading,
    LightboxGallery,
    MapStickyCard,
  },
  mounted() {
    console.log(this.pageData, this.pageData.children, "maparc butt");
    this.getStickyItem();

    this.$root.$on("close-precinct", () => {
      this.renderView2d = null;
    });

    this.$root.$on("clear-render-view-2d", () => {
      this.clearRenderView2d();
    });

    this.$root.$on("clear-render-layer", () => {
      this.renderLayer = null;
    })

    this.$root.$on("open-lightbox", () => {
      this.lightboxReady = true;
    });

    this.$root.$on("clear-loading", () => {
      this.loading = false;
    });

    if (this.pageData && Array.isArray(this.pageData.children)) {
      this.pageData.children.forEach((child, index) => {
        console.log(`HSG Child at index ${index}:`, child);
        if (child.hotspotGroup != null) {
          console.log("HSG!", child.hotspotGroup);
          if (child.hotspotGroup.visible_by_default) {
            const currentVisibileMarkerGroups = [];
            currentVisibileMarkerGroups.push(child.hotspotGroup.id);
            this.$store.commit(
              "wagtail/setVisibleHotspotGroups",
              currentVisibileMarkerGroups
            );
          }
        }
      });
    }

    if (this.pageData.show_legend_header == false) {
      const header_style = `
      h3.esri-widget__heading.esri-legend__service-label {
       display: none !important;
      }

      .esri-legend__service {
          padding-top: 4px !important;
          padding-bottom: 0px !important;
          border-bottom: none !important;
      }`;
      const styleSheet = document.createElement("style");
      styleSheet.innerText = header_style;
      document.head.appendChild(styleSheet);
    }
  },
  created() {},
  computed: {
    resetRenderLayer() {
      return this.$store.state.map.resetRenderLayer;
    },
    layerOptions() {
      return this.$store.getters["wagtail/getLayerOptions"];
    },
    colsBreakpoint() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.xs)
      ) {
        return false;
      } else if (
        this.$vuetify.breakpoint.name == "sm" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.sm)
      ) {
        return false;
      } else if (
        this.$vuetify.breakpoint.name == "md" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.md)
      ) {
        return true;
      } else if (
        this.$vuetify.breakpoint.name == "lg" ||
        (this.srcSize > 0 &&
          this.srcSize < this.$vuetify.breakpoint.thresholds.lg)
      ) {
        return true;
      } else {
        return true;
      }
    },
    selectedLocation() {
      return this.$store.state.map.selectedLocation;
    },
    pageUpdated() {
      return this.pageData;
    },
    markerUpdated() {
      return this.$store.state.currentMarker;
    },
    lightboxContent() {
      if (this.$store.state.wagtail.currentPopup === undefined) {
        return [];
      } else if (this.$store.state.wagtail.currentPopup.question) {
        return this.$store.state.wagtail.currentPopup.question;
      } else return this.$store.state.wagtail.currentPopup.content;
    },
    portalItemId() {
      if (
        this.pageData.esri_map !== undefined &&
        this.pageData.esri_map !== null
      )
        return this.pageData.esri_map.portal_item_id;
      return null;
    },
    portalItemType() {
      if (
        this.pageData.esri_map !== undefined &&
        this.pageData.esri_map !== null
      )
        return this.pageData.esri_map.portal_item_type;
      return null;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    zoomTo() {
      if (this.pageData.zoom !== undefined && this.pageData.zoom !== null)
        return this.pageData.zoom;
      return null;
    },

    accessibilityMarkerZoom() {
      return this.$store.state.map.accessibilityMarkerZoom;
    },
    secondaryColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    precinct() {
      return this.$store.state.map.precinct;
    },
  },
  watch: {
    resetRenderLayer(newValue, oldValue) {
      // React to changes in resetRenderLayer
      if (newValue == true) {
        this.renderLayer = null;
        this.$store.commit("map/setResetRenderLayer", false);
      }
      // Your logic here
    },
    renderLocation: {
      handler(newValue, oldValue) {
        if (newValue == null) {
          this.renderLayer = newValue;
        }
      },
      deep: true,
    },

    precinct: {
      handler(newValue, oldValue) {
        if (newValue != null) {
          console.log("[precinct watcher MAP]", newValue);
          if (this.precinct) {
            if (
              this.precinct.markerZoom == 0 ||
              this.precinct.markerZoom == null
            ) {
              if (this.precinct.precinct_zoom_level != null) {
                this.markerZoom = this.precinct.precinct_zoom_level;
              }
            } else {
              this.markerZoom = this.precinct.markerZoom;
            }

            console.log(
              "[ZOOMING PRE]",
              this.precinct.markerZoom,
              this.precinct.precinct_zoom_level
            );
            this.center = [
              Number(this.precinct.lng),
              Number(this.precinct.lat),
            ];
            this.$refs.map.zoomToLocation(this.markerZoom, this.center, {});
          }
        }
      },
      deep: true,
    },
    selectedLocation: {
      async handler(data) {
        if (data) {
          this.markerZoom = data.zoomLevel;
          this.center = [Number(data.location.lng), Number(data.location.lat)];
          this.cameraTilt = data.tilt_offset;
          this.heading = data.heading;
          await this.$nextTick();
          this.$refs.map.zoomToLocation(this.markerZoom, this.center, {});
          this.zoomMarkerFlag = true;
        } else {
          console.log("no data");
          this.cameraTilt = 0;
          this.heading = 0;
          const center = [Number(this.pageData.lng), Number(this.pageData.lat)];
          this.$refs.map.zoomToCenter(center, this.pageData.zoom, 0); // this.previousFocusItem = document.activeElement;
          // this.markerZoom = this.accessibilityMarkerZoom.zoomLevel;
          // this.center = [
          //   Number(this.accessibilityMarkerZoom.lng),
          //   Number(this.accessibilityMarkerZoom.lat),
          // ];
          // this.$refs.map.zoomToLocation(this.markerZoom, this.center);
          // this.zoomMarkerFlag = true;
        }
      },
      deep: true,
    },
    pageUpdated() {
      this.pageSelected();
    },
    markerUpdated() {
      this.markerSelected();
    },
    markerZoom(newValue) {
      console.log(newValue, "new value of zoom");
    },
    islightboxActive() {
      //return this.$store.state.lightbox.lightboxObjects.length
      if (this.islightboxActive == false) {
        if (localStorage.getItem("accessibilityModeState") != "true") {
          //return focus to the previously focssed item after lightbox closes
          this.$nextTick(() => {
            if (this.previousFocusItem != undefined) {
              this.previousFocusItem.focus();
            }
          });
        }
      }
    },
  },
  methods: {
    mapReady: function () {
      if (this.accessibilityZoom) {
        setTimeout(() => {
          this.previousFocusItem = document.activeElement;
          this.markerZoom = this.accessibilityMarkerZoom.zoomLevel;
          this.center = [
            Number(this.accessibilityMarkerZoom.lng),
            Number(this.accessibilityMarkerZoom.lat),
          ];
          this.$refs.map.zoomToLocation(this.markerZoom, this.center);
          this.zoomMarkerFlag = true;
        }, 2000);
      } else {
        let self = this;
        setTimeout(function () {
          if (!this.accessibilityZoom) {
            self.zoomToMarkers();
            self.getStickyItem();
          }
        }, 2000);
      }
    },
    getStickyItem() {
      let currState = this.pageData.children;
      for (const item of currState) {
        if (item.type === "mapstickyitem") {
          this.$store.dispatch("wagtail/getStickyItem", item.id);
          console.log("success", this.$store.state.wagtail.stickyItem);
          this.currStickyItem = this.$store.state.wagtail.stickyItem;
          console.log("success 2", this.currStickyItem);
        }
      }
    },
    pageSelected: function () {
      this.zoomToMarkers();
    },
    markerClicked: async function (markerData) {
      this.previousFocusItem = document.activeElement;
      console.log(markerData, "WagtailService.getPageById");

      if (markerData.type === "precinctlocation") {
        const data = await this.$store.dispatch(
          "wagtail/getPrecinctChildPage",
          markerData.id
        );
        console.log("[PRECINCT]", data);
        this.$store.commit("map/setPrecinct", data);
        this.$store.commit("map/setGroupLocation", null);
        this.$store.commit("map/setRightSidebarZoomLevel", data.markerZoom);
        return;
      }

      if (markerData.type === "mappage") {
        this.$router.push(new URL(markerData.html_url).pathname);
      }
      if (markerData.type === "redirecturl") {
        window.location.href = markerData.target;
      }
      if (markerData.type == "mapzoomtolocation") {
        this.markerZoom = markerData.zoom;
        console.log("MZ MD", markerData);

        this.center = [Number(markerData.lng), Number(markerData.lat)];
        this.$refs.map.zoomToLocation(this.markerZoom, this.center);
        this.zoomMarkerFlag = true;

        this.$store.dispatch("map/getPopup", markerData.id);

        //Get current popup (clicked) then get all necessary value to create an object that can be passed in the layerOptions
        console.log("MZ pre");
        //const chosenLayer = this.$store.state.map.currentPopup.map_layers[0].value;
        const chosenLayer = markerData.map_layers;
        // this.selectedLayers = chosenLayer

        console.log("MZ layername", chosenLayer);

        this.transformedObject = {
          key: this._uid,
          data: {
            [chosenLayer]: true,
          },
        };

        console.log(this.transformedObject, "payloadMap");

        this.$store.dispatch(
          "wagtail/updateLayerOptions",
          this.transformedObject
        );
      }
      if (markerData.type == "maprenderlocation") {
        this.markerZoom = markerData.marker_zoom;
        console.log("MZ MD", markerData);

        this.center = [Number(markerData.lng), Number(markerData.lat)];
        const data = await this.$store.dispatch(
          "wagtail/getRenderLocationChildPage",
          markerData.id
        );
        this.$store.commit("map/setRenderLocation", true);
        this.$refs.map.zoomToRenderLocation(
          this.center,
          data.tilt_offset,
          this.markerZoom,
          data.heading,
          2000
        );
        setTimeout(() => {
          if (data.location_render_image != null) {
            if (this.$store.state.map.precinct != null) {
              this.$store.commit(
                "map/setBackupPrecinct",
                this.$store.state.map.precinct
              );
            }
            this.$store.commit("map/setPrecinct", null);
            this.renderLayer = data.location_render_image;
            console.log("[RENDER] Not Null", this.renderLayer);
          } else {
            console.log("[RENDER] Null");
          }
        }, 2300); // Delay of 2300 milliseconds

        this.zoomMarkerFlag = true;

        this.$store.dispatch("map/getPopup", markerData.id);

        //Get current popup (clicked) then get all necessary value to create an object that can be passed in the layerOptions

        //const chosenLayer = this.$store.state.map.currentPopup.map_layers[0].value;
      }
      if (markerData.type == "renderview2d") {
        this.showMarkers = false;

        // Hide the right side bar
        if (this.$store.state.map.precinct != null) {
          this.$store.commit(
            "map/setBackupPrecinct",
            this.$store.state.map.precinct
          );
          this.$store.commit("map/setPrecinct", null);
        }

        this.loading = true;

        const data = await this.$store.dispatch("map/getPopup", markerData.id);

        if (data.location_render_image != null) {
          this.renderView2d = data;
        }
      }
      if (markerData.type.includes("popup")) {
        this.$store.dispatch("map/getPopup", markerData.id);
        let id = markerData.id;
        this.lightboxReady = true;
        //TODO: I don't like using the store for this - should call the API service directly
        this.$store.dispatch("wagtail/getPopup", id);
        return (this.dialog.value = true);
      }
    },
    markerSelected: function () {
      //If we have a current marker zoom to it, otherwise zoom to all markers
      if (this.$store.state.currentMarker !== undefined) {
        var center = [
          Number(this.$store.state.currentMarker.long),
          Number(this.$store.state.currentMarker.lat),
        ];
        this.$refs.map.zoomToCenter(
          center,
          this.$store.state.currentMarker.zoom
        );
      } else {
        this.zoomToMarkers();
      }
    },
    zoomToMarkers: function (resetTilt) {
      if (this.pageData !== undefined) {
        //Assemble an array of point long/lats and pass them through to zoom onto
        const pointArray = [];
        this.pageData.children.forEach((element) => {
          if (
            element.icon !== null &&
            element.lng !== null &&
            element.lat !== null
          )
            pointArray.push([Number(element.lng), Number(element.lat)]);
        });

        if (pointArray.length === 0) {
          var center = [Number(this.pageData.lng), Number(this.pageData.lat)];
          console.log("back");
          this.$refs.map.zoomToCenter(center, this.pageData.zoom, 0);
        } else {
          this.$refs.map.zoomToPoints(pointArray);
        }
      }
    },
    closeLightbox() {
      //TODO: Make map popups update routes
      // this.$router.push(new URL(this.pageData.meta.html_url).pathname);
      this.$store.commit("wagtail/getPopupSuccess", undefined);

      this.lightboxReady = false;
    },
    mapMoved(evt) {
      if (this.accessibilityMarkerZoom) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("mapMoved");
        }, 500);
      }
      this.mapCenter = [evt.center.latitude, evt.center.longitude];
    },
    mapZoomed(evt) {
      let previousZoomLevel = this.currZoomLevel;
      this.currZoomLevel = evt;
      console.log(
        "[ZOOM] Current",
        this.currZoomLevel,
        " Previous",
        previousZoomLevel
      );
      if (
        this.$store.state.map.rightSidebarZoomLevel != null &&
        previousZoomLevel > this.currZoomLevel &&
        this.$store.state.map.scrollZoom &&
        this.currZoomLevel < this.$store.state.map.rightSidebarZoomLevel &&
        this.$store.state.map.precinct != null
      ) {
        console.log("SCROLL PASSED PRECINCT CHECK");
        this.$store.commit(
          "map/setBackupPrecinct",
          this.$store.state.map.precinct
        );
        this.$store.commit("map/setPrecinct", null);
        this.$store.commit("map/setRightSidebarZoomLevel", null);
      }
      if (
        this.$store.state.map.rightSidebarZoomLevel != null &&
        previousZoomLevel > this.currZoomLevel &&
        this.$store.state.map.scrollZoom &&
        this.currZoomLevel < this.$store.state.map.rightSidebarZoomLevel &&
        this.$store.state.map.groupLocation != null
      ) {
        console.log("SCROLL PASSED GROUP CHECK");
        this.$store.commit("map/setGroupLocation", null);
        this.$store.commit("map/setRightSidebarZoomLevel", null);
      }

      if (this.currZoomLevel >= this.pageData.zoom * 1.1) {
        // console.log("test", evt , "vs" , this.pageData.zoom,"and", this.pageData.children[0].type)
        //this.markerVisible = false;
      } else this.markerVisible = true;
    },
    getAriaLabel(item) {
      if (item.accessible_label) {
        return item.accessible_label;
      } else {
        return item.title;
      }
    },
    clearRenderView2d() {
      this.renderView2d = null;
      this.showMarkers = true;
    },
  },
};
</script>

<style scoped>

.searchbar {
  position: absolute;
  top: 12px;
  left: 270px;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.map-legend {
  position: absolute;
  bottom: 35px;
  left: 15px;
  width: 344px;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative !important;
}

@media (max-width: 600px) {
  .searchbar {
    width: unset;
    right: 10px;
  }

  .map-legend {
    width: unset;
    right: 15px;
  }
}

.map-sticky-card {
  z-index: 100;
}

>>> .esri-view-width-xsmall
  .esri-expand--auto
  .esri-expand__container--expanded {
  position: relative;
}
</style>
