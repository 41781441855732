<template>
  <div>
    <PageHeaderCard :transparent="false" class="map-header"></PageHeaderCard>
    <div class="map-body">
      <Sidebar :pageData="pageData" class="map-sidebar"></Sidebar>
      <Map
        tabindex="-1"
        :class="'map'"
        :pageData="pageData"
        refs="map"
        @mapMoved="
          accessibilityModalFlag = true;
          accessibilityModalFlag2 = true;
        "
      ></Map>
      <v-slide-x-reverse-transition>
        <RightSidebar
          v-if="precinct || groupLocation"
          :pageData="precinct"
          :groupData="groupLocation"
          :class="'map-sidebar-right'"
        ></RightSidebar>
      </v-slide-x-reverse-transition>
    </div>

    <!-- <LightboxGallery
      :showLightbox="lightboxContent?.length > 0 && mapPageLightboxReady"
      :content="lightboxContent"
      ref="lightbox"
      @closed="closeLightbox()"
    /> -->
    <v-dialog
      content-class="accessibility-lightbox"
      persistent
      v-model="accessibilityModalFlag"
      v-show="accessibilityModalFlag2"
      ref="accessibilityLightbox"
      @keydown.esc="
        (accessibilityModalFlag2 = false),
          (accessibilityModalFlag = false),
          closeAccessibilityLightbox()
      "
    >
      <template v-slot:default="dialog">
        <v-card>
          <FocusLoop :is-visible="activeTrap">
            <v-card-title class="text-h5 accessibility-header lighten-2">
              Accesibility Mode
            </v-card-title>
            <v-tabs grow>
              <v-tab class="accessibility-tab"> Sidebar Content </v-tab>
              <v-tab-item>
                <v-list-item-group
                  v-for="(item, i) in pageData.children"
                  :key="pageData.id + 'page_' + i"
                  link
                  @click="markerClicked(item),                     dialog.value = false;
"
                >
                  <v-list-item
                    v-if="item.type == 'mappage'"
                    class="accessibility-btn"
                    @click="
                      markerClicked(item);
                      dialog.value = false;
                    "
                  >
                    {{ item.title }}
                  </v-list-item>
                </v-list-item-group>
                <v-list-item
                  @keydown.enter.exact.prevent="markerClicked()"
                  @click="(sidebarLightbox = true), (dialog.value = false)"
                  class="accessibility-btn"
                  >Sidebar Overview
                </v-list-item>
                <v-list-item
                  v-if="pageData.next_map !== null"
                  @click="gotoPage(pageData.next_map.html_url)"
                  class="accessibility-btn"
                >
                  Next - {{ pageData.next_map.title }}
                </v-list-item>
                <v-list-item
                  v-if="
                    pageData.root_map !== null &&
                    pageData.root_map.id !== pageData.id
                  "
                  @click="gotoPage(pageData.root_map.html_url)"
                  class="accessibility-btn"
                >
                  Home - {{ pageData.root_map.title }}
                </v-list-item>
              </v-tab-item>
              <v-tab class="accessibility-tab"> Map Content </v-tab>
              <v-tab-item>
                <v-list-item-group
        v-for="(item, i) in pageData.children"
                  :key="pageData.id + 'page_' + i"
                  link
                  class="accessibility-btn"
        @click="markerClicked(item) ,(dialog.value = false)"
                >
                  <v-list-item
                    @click="markerClicked(item), (dialog.value = false)"
                    v-if="
                      item.type == 'mappopup' ||
                      item.type == 'mapzoomtolocation' ||
                      item.type == 'imagepreviewpopup'
                    "
                  >
                    {{ item.title }}
                  </v-list-item>
                </v-list-item-group>
                <v-list-item
                  class="accessibility-btn"
                  @keydown.enter.exact.prevent="markerClicked()"
                  @click="(mapLightbox = true), (dialog.value = false)"
                  >Map Overview</v-list-item
                >
                <v-list-item
                  class="accessibility-btn"
                  v-if="pageData.next_map !== null"
                  @click="gotoPage(pageData.next_map.html_url)"
                >
                  Next - {{ pageData.next_map.title }}
                </v-list-item>
                <v-list-item
                  v-if="
                    pageData.root_map !== null &&
                    pageData.root_map.id !== pageData.id
                  "
                  class="accessibility-btn"
                  @click="gotoPage(pageData.root_map.html_url)"
                >
                  Home - {{ pageData.root_map.title }}
                </v-list-item>
              </v-tab-item>
              <v-tab>Site Navigation </v-tab>
              <v-tab-item>
                <v-list-item
                  v-for="(menuItem, i) in siteStructure"
                  :key="i"
                  :to="menuItem.pathname"
                  link
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>{{ menuItem.menu_icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                </v-list-item></v-tab-item
              >
            </v-tabs>

            <v-divider></v-divider>
            <v-btn
              class="close-accessibility-btn"
              tabindex="0"
              @click="closeAccessibilityLightbox(), (dialog.value = false)"
              >Close</v-btn
            >
          </FocusLoop>
        </v-card>
      </template>
    </v-dialog>

    <!-- Map Overview -->
    <MapLightbox
      @closed="(mapLightbox = false), (accessibilityModalFlag = true)"
      :pageData="pageData"
      :showLightbox="showLightbox"
      :accessibilityMode="accessibilityMode"
      :mapLightbox="mapLightbox"
    />
    <!-- End of Map Overview -->
    <!-- Do not remove this  -->
    <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      @closed="closeLightbox()"
    />

    <LightboxBase
      @closed="(sidebarLightbox = false), (accessibilityModalFlag = true)"
      v-if="sidebarLightbox"
      :showLightbox="showLightbox"
      ref="lightbox"
    >
      <Sidebar
        :pageData="pageData"
        :class="'map-sidebar-accessibility-mode'"
      ></Sidebar>
    </LightboxBase>
  </div>
</template>

<script>
import RightSidebar from "../../components/sidebar/RightSidebar.vue";
import Sidebar from "../../components/sidebar/Sidebar.vue";
import Map from "../../components/esrimap/Map.vue";
import LightboxBase from "../../components/lightbox/LightboxBase.vue";
import PageHeaderCard from "../../components/page/pageHeaderCard.vue";
import { FocusLoop } from "@vue-a11y/focus-loop";
import * as arrowKeyNavigation from "arrow-key-navigation";
import MapLightbox from "../../components/lightbox/MapLightbox.vue";

//enables next & previous tab style DOM navigation using arrow keys
arrowKeyNavigation.register();

export default {
  name: "MapPage",
  props: {
    pageData: Object,
    pageChildren: Array,
    showLightbox: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
  },
  components: {
    RightSidebar,
    Sidebar,
    Map,
    PageHeaderCard,
    FocusLoop,
    LightboxBase,
    MapLightbox,
  },
  data: () => ({
    accessibilityTextFlag: false,
    lightboxData: undefined,
    showPopup: false,
    counter_flag: 0,
    isMobile: false,
    accessibilityFlag: false,
    mapLightbox: false,
    sidebarLightbox: false,
    focused: false,
    showLanding: Boolean,
    accessibilityModalFlag: false,
    accessibilityModalFlag2: false,
    tab_counter: -1,
    map_flag: false,
    popupFlag: true,
    sidebar_flag: true,
    sidebarcontent: 0,
    mapcontent: 0,
    activeTrap: true,
    previousMenuItem: undefined,
    groupLocationData: null,
    renderView2d: null,
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
    console.log("hello");
  },
  mounted() {
    this.accessibilityFlagChecker();

    console.log(this.pageData, this.showLanding, "page data from webpage");

    var bodyElement = document.querySelector("body");
    var htmlElement = document.querySelector("html");
    bodyElement.classList.add("body-scrolldisable");
    htmlElement.classList.add("html-scrolldisable");

    this.onResize();
    for (var i = 0; i < this.pageData.children.length; i++) {
      if (this.pageData.children[i].type == "mappage") {
        this.sidebarcontent++;
      }
    }
    for (var m = 0; m < this.pageData.children.length; m++) {
      if (
        this.pageData.children[m].type == "mappopup" ||
        this.pageData.children[m].type == "mapzoomtolocation"
      ) {
        this.mapcontent++;
      }
    }
  },
  computed: {
    precinct() {
      return this.$store.state.map.precinct;
    },
    groupLocation() {
      const data = this.$store.state.map.groupLocation;
      console.log("groupLocation clicked computed property updated", data);
      return data;
    },
    mapPageLightboxReady() {
      return this.$store.state.wagtail.mapPageLightboxReady;
    },
    pageUpdated() {
      return this.pageData;
    },
    currentPath() {
      return this.$route.path;
    },
    lightboxContent() {
      if (this.$store.state.wagtail.currentPopup === undefined) {
        return [];
      } else if (this.$store.state.wagtail.currentPopup.question) {
        return this.$store.state.wagtail.currentPopup.question;
      } else return this.$store.state.wagtail.currentPopup.content;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        item.depth = item.meta.html_url.split("/").length - 4;
        let url = new URL(item.meta.html_url);
        item.pathname = url.pathname;
        item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
        siteStructure.push(item);
      }

      return siteStructure;
    },
  },
  methods: {
    markerClicked(markerData) {
      this.previousMenuItem = document.activeElement;
      console.log(markerData, "WagtailService.getPageById");

      if (markerData.type === "mappage") {
        this.$router.push(new URL(markerData.html_url).pathname);
      }
      if (markerData.type === "mapzoomtolocation") {
        this.mapLightbox = true;
        this.$store.commit("map/accessibilityMarkerZoom", markerData);

        this.accessibilityModalFlag2 = false;
        this.accessibilityModalFlag = false;
        this.closeAccessibilityLightbox();
      }
      if (markerData.type.includes("popup")) {
        this.$store.dispatch("map/getPopup", markerData.id);
        let id = markerData.id;
        this.lightboxReady = true;
            this.accessibilityModalFlag2 = false;
        this.accessibilityModalFlag = false;
        //TODO: I don't like using the store for this - should call the API service directly
        this.$store.dispatch("wagtail/getPopup", id);
        return (this.dialog.value = false);
      }
    },
    welcomeClosed() {
      this.showLanding = false;
      sessionStorage.setItem("showLanding", false);
    },

    // isRootPage() {
    //   let currPath = this.currentPath;
    //   let pagePath = new URL(this.pageData.meta.html_url).pathname;
    //   console.log(pagePath, this.currentPath , "pagepath")
    //   if (currPath === pagePath) return true;
    //   return false;
    // },
    // shortcutClicked(url) {
    //   //When we click a shortcut, we either route to that page, or if the shortcut is the current page we close the dialog
    //   let pagePath = new URL(url).pathname;
    //   let currPath = this.currentPath;

    //   if (currPath === pagePath) this.welcomeClosed();
    //   else this.$router.push(pagePath);
    // },

    openMapLightbox() {
      if (this.mapLightbox == false) {
        this.mapLightbox = true;
      } else {
        this.mapLightbox = false;
      }
    },
    onResize() {
      this.isMobile = window.screen.width < 600;
    },
    closeLightbox() {
      //TODO: Make map popups update routes
      // this.$router.push(new URL(this.pageData.meta.html_url).pathname);
      this.$store.commit("wagtail/getPopupSuccess", undefined);

      //Return focus to previous element
      //this.$refs.sidebar.$children[this.counter_flag].$el.focus();
    },
    closeAccessibilityLightbox() {
      this.accessibilityModalFlag = false;
      this.accessibilityModalFlag2 = false;
      this.$store.commit(
        "wagtail/setAccessibiltyMode",
        this.accessibilityModalFlag,
        { root: true }
      );
      localStorage.setItem("accessibilityModeState", false);
    },
    accessibilityFlagChecker() {
      let accessibilityState = localStorage.getItem("accessibilityModeState");
      if (accessibilityState == "true") {
        this.accessibilityModalFlag = true;
        this.accessibilityModalFlag2 = true;
      } else {
        this.accessibilityModalFlag = false;
        this.accessibilityModalFlag2 = false;
      }
      this.$store.commit(
        "wagtail/setAccessibiltyMode",
        this.accessibilityModalFlag,
        { root: true }
      );
    },
    gotoPage(url) {
      this.$router.push(new URL(url).pathname);
    },
  },

  watch: {
    groupLocation: {
      handler(newValue, oldValue) {
        this.groupLocationData = newValue;
        console.log("clicked new data", this.groupLocation);
      },
      deep: true,
    },
    markerUpdated() {
      this.markerSelected();
    },
    accessibilityMode(newValue) {
      if (newValue == true) {
        this.accessibilityModalFlag = true;
        this.accessibilityModalFlag2 = true;
        localStorage.setItem("accessibilityModeState", true);
      } else {
        this.accessibilityModalFlag = false;
        this.accessibilityModalFlag2 = false;
        localStorage.setItem("accessibilityModeState", false);
      }
    },

    onResize() {
      this.onResize();
    },
    islightboxActive() {
      //return this.$store.state.lightbox.lightboxObjects.length
      if (this.islightboxActive == false) {
        if (localStorage.getItem("accessibilityModeState") == "true") {
          this.accessibilityModalFlag = true;
          this.accessibilityModalFlag2 = true;
        } else {
          this.accessibilityModalFlag = false;
          this.accessibilityModalFlag2 = false;
        }
        //return focus to the previously focssed item after lightbox closes
        this.$nextTick(() => {
          if (this.previousMenuItem != undefined) {
            //cleanup previously focussed item 'active' shading
            this.previousMenuItem.classList.remove("v-item--active");
            this.previousMenuItem.classList.remove("v-list-item--active");
            this.previousMenuItem.focus();
          }
        });
      } else {
        this.accesibilityModal = false;
        this.accessibilityModalFlag2 = false;
      }
    },
  },
};
</script>

<style scoped>
.map-body {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.map-header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.map-sidebar {
  position: absolute;
  top: 0px;
  left: 0px;
  /* width: 344px; */
  width: 500px;
  height: 100%;
}

.map-sidebar-right {
  position: absolute;
  top: 0px;
  right: 0;
  width: 300px;
  height: 100%;
}

.map-sidebar-accessibility-mode {
  background: white;
  height: 100%;
}

.map {
  position: absolute;
  top: 0px;
  left: 500px;
  right: 0px;
  height: 100%;
}
.map-accessibility-mode {
  position: absolute;
  top: 0px;
  left: 750px;
  right: 0px;
  height: 100%;
}

.map-accessible-sidebar {
  position: absolute;
  top: 0px;
  left: 0px;
  /* /  width: 500px; */
  height: 100%;
}

.map-legend {
  display: none;
}

.accessibility_sidebar {
  position: absolute;
  left: 26%;
  /* width: 200px; */
}

#accessibility-btn:focus-visible {
  outline: 4px dashed darkorange;
}

::v-deep .accessibility-lightbox {
  min-width: 300px;
  width: 25vw;
}

.accessibility-header {
  background-color: black;
  color: white;
}

@media (max-width: 600px) {
  .map-body {
    top: 56px;
  }

  .map-sidebar {
    height: 50%;
    width: 100%;
    top: 50%;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .map-sidebar-right {
    height: 50%;
    width: 100%;
    top: 50%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: +999;
  }

  .map {
    height: 50%;
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 50%;
  }

  .map-legend {
    display: block;
  }
}
</style>
