<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      v-for="(value, key) of data"
      :key="key"
      >
      <StreamfieldBlock v-for="(block, i) in value" :key="i" :block="block"/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "TwoColumnBlock",
  props: ["data"],
  beforeCreate: function () {
    //Prevents recustive importing...
    this.$options.components.StreamfieldBlock = require('../streamfieldBlock.vue').default
  }
};
</script>

<style scoped>
</style>