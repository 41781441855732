<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
      md="4"
      lg="4"
      xl="4"
      >
      <StreamfieldBlock v-for="(block, i) in data.column_0" :key="i" :block="block"/>
    </v-col>
    <v-col
      cols="12"
      sm="8"
      md="8"
      lg="8"
      xl="8"
      >
      <StreamfieldBlock v-for="(block, i) in data.column_1" :key="i" :block="block"/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "PrimaryRightColumnBlock",
  props: ["data"],
  beforeCreate: function () {
    //Prevents recustive importing...
    this.$options.components.StreamfieldBlock = require('../streamfieldBlock.vue').default
  }
};
</script>

<style scoped>
</style>