export const lightbox = {
  namespaced: true,
  state: {
    lightboxObjects: [],
  },
  actions: {
    TOGGLE_HOME_NAVIGATION_DRAWER(context, open) {
      context.commit('TOGGLE_HOME_NAVIGATION_DRAWER', open)
    },
    pushLightbox(context, object) {
      context.commit('pushLightbox', object)
    },
    popLightbox(context, object) {
      context.commit('popLightbox', object)
    }
  },
  mutations: {
    TOGGLE_HOME_NAVIGATION_DRAWER: (state, open) => {
      state.isHomeNavigationDrawerOpen = open
    },
    pushLightbox: (state, object) => {
      console.log("New lightbox added")
      console.log(object)
      console.log("-------")
      state.lightboxObjects.push(object)
    },
    popLightbox: (state, object) => {
      console.log("Lightbox removed")
      console.log(object)
      console.log("-------")
      
      var i = 0;
      while (i < state.lightboxObjects.length) {
        if (state.lightboxObjects[i] === object) {
          state.lightboxObjects.splice(i, 1);
        } else {
          ++i;
        }
      }
      return state.lightboxObjects;

    }
  },
};
