<template>
  <v-scale-transition origin="center center">
    <v-card shaped class="instruction-card">
      <div class="text-center">
      <v-icon color="white" size="130">{{mdiRotateOrbit}}</v-icon>
      </div>
      <v-card-text class="text-center text-description">
        <h2 class="white--text">{{message}}</h2>
      </v-card-text>
    </v-card>
  </v-scale-transition>
</template>

<script>
import { mdiRotateOrbit } from '@mdi/js'
export default {
  name: "PanoramaInstructions",
  props: ["title"],
  data: () => ({
    mdiRotateOrbit: mdiRotateOrbit,
    items: [],
    select: undefined,
    search: undefined,
    loading: undefined,
    toggle_exclusive: 1,
    message: "Drag to look around"
  }),
};
</script>

<style scoped>
.instruction-card {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: none;
  width: 230px;
  height: 175px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -87.5px;
  margin-left: -115px;
}

.lottie-anim {
  position:absolute;
  top:50%;
  left:50%;
  width: 200px; 
  height: 200px; 
  margin-top:-120px; 
  margin-left:-100px
}

.text-description {
  position:absolute;
  bottom:0px;
}

</style>