<template>
  <v-card light class="content-container">
    <!-- <v-toolbar dense dark flat color="primary">
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
    </v-toolbar> -->
    <v-card-text class="scrollable">
      <WebPageLegacy
        v-if="pageData !== undefined"
        :pageData="pageData"
        fill-width
        :showTitle="false"
        :showHeader="false"
      />
    </v-card-text>

  </v-card>
</template>

<script>
import WebPageLegacy from "../../views/wagtail/WebPageLegacy";

export default {
  name: "LightboxWagtailPage",
  data: () => ({
    pageData: undefined,
  }),
  props: ["data"],
  components: { WebPageLegacy },
  mounted() {
    // Ajax request the page content
    // Once page content is established, render page
    console.log(this.data.meta.detail_url);
    let self = this;
    this.$store
      .dispatch("wagtail/getPageByUrl", this.data.meta.detail_url)
      .then((response) => {
        self.pageData = response;
      });
  },
  computed: {
    pageTitle() {
      if (this.pageData != undefined)
        return this.pageData.title
      return ""
    }
  }
};
</script>


<style scoped>
.video {
  width: 100%;
  max-height: 100%;
  margin: 0 auto;
  text-align: center;
  /* background-color: black; */
}

.content-container {
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-y: none;
}

/* .v-card {
  display: flex !important;
  flex-direction: column;
}
*/
.scrollable {
  position:absolute;
  top:0px;
  bottom:0px;
  overflow-y: auto;
} 

</style>