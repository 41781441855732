<template>
  <v-container fill-height class="fullscreen">
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-icon x-large class="pb-6" color="primary">mdi-emoticon-sad-outline</v-icon>
        <p>We couldn't find the page you were looking for.</p>
        <p>This page doesn't exist or you don't have permission to view it.</p>
        <p v-if="!loggedIn">Have you tried <a :href="loginUrl">logging in?</a></p>
      </v-col>
    </v-row>
  
  </v-container>
</template>

<script>
// import Streamfield from "../../components/blocks/streamfieldBlock.vue";

export default {
  name: "Error404",
  props: {
  },
   metaInfo: {
     meta: [
      { name: 'robots', content: 'noindex' }
    ]
    },
  // components: { Streamfield },
  data: () => ({}),
  mounted() {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
    loginUrl() {
      return "/login?redirect=" + window.location.pathname
    }
  },
  methods: {},
};
</script>

<style scoped>

.fullscreen {
  position:absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}
</style>