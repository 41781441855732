<template>

  <div
  class = "panorama noscrollbars"
    :style="{
      width: '100%',
      height: '100%',
    }"
  >    
  
    <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      :tileUrl="lightboxContent"
      @closed="closeLightbox()"
      tabindex="0"
    />

   <Marzipano
      :tileUrl="tileUrl"
      :data="data.data"
      :ready="autoRotate"
      :sasKey="data.sas_key"
      @loaded="panoLoaded"
      ref="Marzipano"
      :minYaw="data.min_yaw"
      :maxYaw="data.max_yaw"
      tabindex="-1"
    >
      <div v-for="(child, i) in currentChildren" :key="i">
        <MarzipanoRectHotspot
          v-if="child.type === 'virtualroomcontent'"
          :index="i"
          :yaw="child.position_data.yaw"
          :pitch="child.position_data.pitch"
          :rotX="child.position_data.rotX"
          :rotY="child.position_data.rotY"
          :rotZ="child.position_data.rotZ"
          :width="child.position_data.width"
          :height="child.position_data.height"
          :thumbnail="child.thumbnail"
          :scene="scene"
          :title="getChildTitle(child)"
          @click="clickChild(child)"
          :icon="child.menu_icon"
          :clickable="child.clickable"
          :transparent="child.transparent_background"
          :lightboxActive="lightboxActive"
          ref="rectHotspot"
        />
      </div>
      <div v-for="(child, i) in currentPanoChildren" :key="i">
        <MarzipanoRectHotspot
          v-if="child.type === 'panoramaroompopup'"
          :index="i"
          :yaw="child.position_data.yaw"
          :pitch="child.position_data.pitch"
          :rotX="child.position_data.rotX"
          :rotY="child.position_data.rotY"
          :rotZ="child.position_data.rotZ"
          :width="child.position_data.width"
          :height="child.position_data.height"
          :thumbnail="child.thumbnail"
          :scene="scene"
          :title="getChildTitle(child)"
          :icon="child.menu_icon"
          :clickable="child.clickable"
          :transparent="child.transparent_background"
          :lightboxActive="lightboxActive"
          @click="clickChild(child)"
          ref="rectHotspot"
        />
       </div>
   </Marzipano>
    <!-- <div
      :style="{
        width: '100%',
        height: '15%',
      }"
      v-if="hasCaption"
      class="caption-div"
    >
      <v-container v-if="hasCaption" fluid class="pa-2 lb-caption">
        <v-row justify="center" align="center" class="fill-height row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="10"
            xl="8"
            class="caption-col"
            justify="center"
            align="center"
          >
            <div class="white--text">{{ data.caption }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    </div> -->

    <!-- <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      @closed="closeLightbox()"
    /> -->
        <PanoramaInstructions v-show="showInstructions" />

  </div>
</template>

<script>
import Marzipano from "../panorama/Marzipano.vue";
import MarzipanoRectHotspot from "../panorama/MarzipanoRectHotspot";
import PanoramaLoading from "../panorama/PanoramaLoading.vue";
import LightboxGallery from "../lightbox/LightboxGallery.vue";
import PanoramaInstructions from '../panorama/PanoramaInstructions.vue';

export default {
  name: "LightboxCustomPanoramaCard",
  data: () => ({
     panorama: "/engagement_room.json",
    lightboxData: undefined,
    scene: undefined,
    lastYaw: undefined,
    lastPitch: undefined,
    lastFov: undefined,
    useLastLocation: false,
    lightboxReady: false,
    showInstructions: false,
    instructionsShown: false,
    autoRotate: false,
    consoleYaw: undefined,
    consolePitch: undefined,
    formYaw: undefined,
    formPitch: undefined,
    lightboxActive: undefined,
    currentFocusIndex: 0,
    currentActiveHotspot: undefined,
    currentActiveAccessibilityMenuItem: undefined,
    isMouseControl: false,
    isMoving: false,
    movementSpeed: 600,
    accessibilityModalFlag: false,
    accessibilityFlag: false,
    activeTrap: true,
    lightbox2Ready:false
  }),
  props: ["data", "tiles"],
  components: {
    MarzipanoRectHotspot,
    Marzipano,
    PanoramaLoading,
    PanoramaInstructions,
    LightboxGallery,
  },
  created() {

  },
  mounted() {
    console.log(this.data,"data from panorama card");

    
  },
  methods: {
    onFocus() {
      this.isMoving = true;
    },
   clickChild(child) {
        console.log('child clicked', child)
    this.lightboxReady = true;

    let currentUILocation = document.activeElement;
      if (
        currentUILocation.id == document.querySelectorAll("#hotspot-img")[0].id
      ) {
        this.currentActiveHotspot = currentUILocation;
        this.isMouseControl = false;
      } else if (currentUILocation.classList[0] == "v-image") {
        //reset focus location storage if mouse is used.
        this.currentActiveHotspot = undefined;
        this.currentActiveAccessibilityMenuItem = undefined;
        this.isMouseControl = true;
      } else if (currentUILocation.classList[0] == "accessibility-btn") {
        this.currentActiveAccessibilityMenuItem = currentUILocation;
        this.isMouseControl = false;
      } else {
        console.log(
          "Opening lightbox NO MATCH, new access method detected. update focus tracking script"
        );
      }

      //Open URL
      this.$router.push(new URL(child.html_url).pathname);
    },
    getLightboxData(child) {
      let id = child.id;
      let zoomData = child.position_data;
      this.lightboxReady = false;
      //TODO: I don't like using the store for this - should call the API service directly
      console.log(this.$store.dispatch("wagtail/getPopup", id), "dispatch");

      this.$store.dispatch("wagtail/getPopup", id);
        
      var destinationViewParameters = {
        yaw: zoomData.yaw,
        pitch: zoomData.pitch,
        fov: (40 * Math.PI) / 180,
      };

      var options = {
        transitionDuration: 1500,
      };

      this.scene.lookTo(destinationViewParameters, options);
      // Might be useful chose not to remove
      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      // var bodyElement = document.querySelector("body");
      // bodyElement.scroll(0, 0);

      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      window.setTimeout(function () {
        this.lightboxReady = true;
      }, 1250);
    },
     checkZoomToChild() {
      let currPath = window.location.pathname;
      let pagePath = new URL(this.$store.state.wagtail.currentPopup.meta.html_url).pathname;

      //If we're not on the root page
      if (pagePath !== undefined && currPath !== undefined) {
        pagePath = pagePath.trimRight("/");
        currPath = currPath.trimRight("/");

        // If the requested path is not the same as the current page path, then we may be requesting a child object
        if (pagePath !== currPath) {
          // Search the children for a valid child
          for (let child of this.$store.state.wagtail.currentPopup.child_content) {
            let childPath = new URL(child.html_url).pathname;
            if (childPath !== undefined) {
              childPath = childPath.trimRight("/");

              // We've found the right child, let's zoom to it and open the lightbox
              if (childPath === currPath) {
                this.getLightboxData(child);
                return;
              }
            }
          }
        for (let anotherChild of this.$store.state.wagtail.currentPopup.panorama_child_content) {
          let anotherChildPath = new URL(anotherChild.html_url).pathname;
          if (anotherChildPath !== undefined) {
            anotherChildPath = anotherChildPath.trimRight("/");
            if (anotherChildPath === currPath) {
              this.getLightboxData(anotherChild);
              return;
            }
          }
        }
        }
   
      }
      // TODO: if we don't find a valid child, reset the scene or reload the page...
    },
     closeLightbox() {
      //Updates trackers for hotspot status and reopens the accessibility menu if in accessibility mode.
      //this.lightboxActive = false;
        console.log("new url from close lightbox", this.$store.state.wagtail.currentPopup.meta.html_url)
      this.$router.push(new URL(this.$store.state.wagtail.currentPage.meta.html_url).pathname);

      this.$store.commit("wagtail/getPopupSuccess", undefined);
      //Tracks that the camera is moving so that keyboard controls cannot override transition animation
      this.isMoving = true;

      // If we've flagged to use the last location, use it and clear the flag
      if (this.useLastLocation === true) {
        destinationViewParameters = {
          yaw: this.lastYaw,
          pitch: this.lastPitch,
          fov: this.lastFov,
        };
        this.useLastLocation = false;
      } else {
        var destinationViewParameters = {
          yaw: this.scene.view().yaw(),
          pitch: 0.0,
          fov: (60 * Math.PI) / 180,
        };
      }

      var options = {
        transitionDuration: this.movementSpeed,
      };

      // Display the instructions if they've not been displayed before
      // This is to handle use cases where a user has navigated straight
      // to some lightbox content, and hasn't come via the traditional entry point

      if (this.instructionsShown === false) this.displayInstructions();
      this.scene.lookTo(destinationViewParameters, options);

      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      // var bodyElement = document.querySelector("body");
      // bodyElement.scroll(0, 0);
      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      //use stored values to return focus to the correct previous location in virtual room or accessibility menu
      const elements = document.querySelectorAll("#hotspot-img");

      if (
        this.currentActiveHotspot != undefined &&
        this.accessibilityMode == false
      ) {
        // Moves focus to previous hotspot location in virtual room
        this.currentActiveHotspot.focus();
      } else {
        if (this.accessibilityMode == false) {
          //Moves focus Back to the first hotspot if not using a mouse"
          if (this.isMouseControl == false) {
            elements[0].focus();
          }
        } else {
          if (this.currentActiveAccessibilityMenuItem != undefined) {
            //Moves focus Back to the previous accessibility menu item
            this.$nextTick(() => {
              this.currentActiveAccessibilityMenuItem.focus();
            });
          }
        }
      }
    },
    isRootPage() {
      let currPath = this.currentPath;
      let pagePath = new URL(this.$store.state.wagtail.currentPopup.meta.html_url).pathname;
      if (currPath === pagePath) return true;
      return false;
    },

     panoLoaded(scene) {
        console.log(scene, "scene from custompano")
      this.scene = scene;
      this.checkZoomToChild();
    },
    getChildTitle(child){
        console.log(child, "test-childtitle")
      if (child.icon_only) return "";
      return child.title;
    }
  },
  computed: {
    image() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
    currentPath() {
      return this.$route.path;
    },
    tileUrl() {
      return this.data.tiles.split("?")[0];
    },
    currentChildren() {
      return this.$store.state.wagtail.currentPopup.child_content;
    },
    currentPanoChildren(){
        return this.$store.state.wagtail.currentPopup.panorama_child_content;
    },
    lightboxContent(res) {
      if (this.$store.state.wagtail.currentPopup === undefined) return [];
      else return this.$store.state.wagtail.currentPopup.content;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // hotspotContent(res) {
    //   console.log("changed val", res);
    //   this.hotspotContent = res;
    //   this.lightboxReady = true
    // }
    lightboxReady(res){
        console.log("lightbox ready val", res)
         this.lightbox2Ready = true
    },
    currentPath() {
        console.log("current path from panocard changed")
      this.checkZoomToChild();
      //Hide the landing page if the path has changed
      if (!this.isRootPage() && this.showLanding) this.showLanding = false;
    },
    lightboxContent(res){
        console.log('lightbox content from panocard', res.length, res)
        this.lightboxReady = true
    },
    islightboxActive() {
      if (this.islightboxActive == false) {
        if (this.accessibilityMode == true) {
          this.accessibilityModalFlag = true;
        } else {
          this.accessibilityModalFlag = false;
        }
      } else {
        this.accesibilityModal = false;
      }
    },
    isMoving() {
      //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
      // window.scrollTo(0, 0);
      //  var bodyElement = document.querySelector("body");
      //  bodyElement.scroll(0, 0);
      var panoramas = document.getElementsByClassName("panorama");
      for (let i = 0; i < panoramas.length; i++) {
        panoramas[i].scroll(0, 0);
        console.log("Pano " + i + " scrolling");
      }

      if ("activeElement" in document) {
        const elements = document.querySelectorAll("#hotspot-img");
        if (document.activeElement.id == elements[0].id) {
          this.currentActiveHotspot = document.activeElement;
        }
      }
      if (this.isMoving) {
        arrowKeyNavigation.unregister();
        window.onkeydown = () => false;
        window.setTimeout(() => {
          this.isMoving = false;
        }, this.movementSpeed);
      } else {
        arrowKeyNavigation.register();
        window.onkeydown = () => true;

        //Resetting Window, body and panorama scroll location to zero to counteract marzipano bug that moves the viewport offscreen
        // window.scrollTo(0, 0);
        // var bodyElement = document.querySelector("body");
        // bodyElement.scroll(0, 0);
        for (let i = 0; i < panoramas.length; i++) {
          panoramas[i].scroll(0, 0);
          console.log("Pano " + i + " scrolling");
        }
      }
    },
  },
};
</script>


<style scoped>

.panorama {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.lb-caption {
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.caption-col {
  overflow-y: auto;
  overflow-x: hidden;
}

.caption-div {
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 600px) {
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>