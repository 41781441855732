var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollable",staticStyle:{"z-index":"3","background-color":"white"},attrs:{"data-testid":"map-sidebar","id":"sidebar-container"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.testFunc()}}},[_c('v-toolbar',{attrs:{"dark":_vm.primaryIsDark ? true : false,"color":"primary lighten-1","elevation":"0"}},[_c('v-btn',{attrs:{"dark":_vm.primaryIsDark ? true : false,"tabindex":"-1","icon":""},on:{"click":function($event){return _vm.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-divider',{staticClass:"pl-2",attrs:{"vertical":""}}),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(_vm.pageData.title))]),_c('v-spacer')],1),_c('v-container',{staticClass:"pb-0"},_vm._l((_vm.pageData.description),function(block,i){return _c('v-row',{key:i},[_c('v-col',[_c('Streamfield',{attrs:{"block":block}})],1)],1)}),1),(_vm.childPages.length > 0)?_c('v-container',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-3"},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","color":"secondary","border":"left"}},[_vm._v(" Click on the topics below for more information ")])],1)],1),_vm._l((_vm.childPages),function(menuItem,i){return _c('v-row',{key:i},[_c('v-col',{staticClass:"pb-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{style:(_vm.getBorderStyle(
                  _vm.getPrimaryColour(menuItem.primary_colour_override)
                )),attrs:{"elevation":hover ? 4 : 1},on:{"click":function($event){return _vm.clickChild(menuItem)}}},[_c('v-card-text',{staticClass:"text-subtitle-1 card-text"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":_vm.getPrimaryColour(menuItem.primary_colour_override)}},[_vm._v(_vm._s(menuItem.menu_icon))]),_vm._v(_vm._s(menuItem.title))],1)],1)]}}],null,true)})],1)],1)}),_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0"},[_c('v-divider')],1)],1)],2):_vm._e(),_c('v-container',[(_vm.pageData.next_map !== null)?_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-btn',{attrs:{"block":"","color":"primary lighten-1"},on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v("Next - "+_vm._s(_vm.pageData.next_map.title))])],1)],1):_vm._e(),(
        _vm.pageData.prev_map !== null &&
        _vm.pageData.root_map.id !== _vm.pageData.prev_map.id
      )?_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-btn',{attrs:{"block":"","color":"primary lighten-1"},on:{"click":function($event){return _vm.gotoPage(_vm.pageData.prev_map.html_url)}}},[_vm._v("Prev - "+_vm._s(_vm.pageData.prev_map.title))])],1)],1):_vm._e(),(
        _vm.pageData.root_map !== null && _vm.pageData.root_map.id !== _vm.pageData.id
      )?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary lighten-1"},on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v("Home - "+_vm._s(_vm.pageData.root_map.title))])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }