<template>
  <div>
    <v-slider :tick-labels="tickLabels" min=0 :max="tickLabels.length-1" tick-size="4" v-model="sliderValue" color="secondary"></v-slider>
  </div>
</template>

<script>
export default {
  name: "ImageBlock",
  props: ['sliderData'],
  data: () => ({
    sliderValue: 0
  }),
  created() {
    //Ensure the first filter is applied at load
    let filter = this.sliderData[this.sliderValue].where
    this.$store.commit('map/setLayerFilter', {where: filter})
  },
  computed: {
    tickLabels: function() {
      let labels = []
      this.sliderData.forEach( function(item) {
        labels.push(item.step)
      })
      return labels
    }
  },
  watch: {
    sliderValue: function(newValue) {
      //TODO: This filtering should check out if this layer actually has the fields being filtered to prevent warnings
      let filter = this.sliderData[newValue].where
      this.$store.commit('map/setLayerFilter', {where: filter})
    }
  },
  beforeDestroy() {
    this.$store.commit('map/setLayerFilter', null)
  }
};
</script>

<style scoped>
</style>